<div class="banner">
  <img src="assets/img/Services-4.png" class="img-fluid" />
  <div>
    <h2>Our Services</h2>
  </div>
</div>

<div class="our-story">
  <h2>Specialized and Personalized Services</h2>
</div>

<div class="how-works-content gradient">
  <div class="container">
    <div class="how-works mb-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                24x7 live counselling support provided by experts. along with
                téléconsultation/ in-person consultations available.
              </p>
              <p>
                Holistic wellness programs for students dealing with physical &
                mental health issues
              </p>
              <p>
                Incorporation of Ayurveda and alternative medicine into wellness
                curricula.
              </p>
              <p>
                Expert-led workshops and seminars on holistic health practices.
              </p>
              <p>
                Individual goal-based plans and personal prescription packages
                are available.
              </p>
              <p>Group sessions for collective wellbeing of the team.</p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/SpecializedPersonalized-1.png" />
              <div class="how-w-img1-text"><h1>1</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-7 order-1">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/SpecializedPersonalized-2.png" />
              <div class="how-w-img1-text"><h1>2</h1></div>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-2">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                2000+ research articles and studies available for educational
                purposes. We also have support & discussion forums to build a
                safe space for mental wellness.
              </p>
              <p>
                Unlimited Audio/Video sessions must be available for users who
                would like to avail escalated support.
              </p>
              <p>
                Wellbe's technology platform for self-care resources and
                tracking progress.
              </p>
              <p>
                Customized wellness initiatives for stress management and mental
                wellbeing.
              </p>
              <p>
                Awareness webinars/seminars and/or large Audience motivational
                intervention to promote student engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                Data-driven insights to assess the effectiveness of wellness
                programs on campus.
              </p>
              <p>
                Through Virtual Reality Exposure Therapy and psychoeducational
                workshops designed to help build personal awareness, develop
                coping skills and promote self care, we can increase the impact
                of the solutions.
              </p>
              <p>
                A complete support system to help students overcome key mental
                issues arising from
              </p>
              <ul>
                <li>grief & loss</li>
                <li>transition, change & uncertainty</li>
                <li>homesickness & social isolation</li>
                <li>family concerns, struggle with relationships</li>
                <li>insomnia, obsessive & compulsive behaviour</li>
                <li>suicidal tendencies, depression, stress, anxiety</li>
                <li>academic pressure and more factors.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="">
            <div class="how-w-img1">
              <img src="assets/img/SpecializedPersonalized-3.png" />
              <div class="how-w-img1-text"><h1>3</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <app-connect-with></app-connect-with>
    </div>
  </div>
</div>
