import { animate, style, transition, trigger } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, lastValueFrom, takeUntil } from 'rxjs';
import { CountryResModel } from '../models/country.model';
import { LookupResModel } from '../models/lookup.model';
import { AuthService } from '../serivce/auth.service';
import { CountryService } from '../serivce/country.service';
import { ExternalServiceBookingService } from '../serivce/external-service-booking.service';
import { LookupService } from '../serivce/lookup.service';
import {
  FormErrorMessage,
  NumericValidator,
} from '../shared/custom.validators';
import { NotificationService } from '../shared/notification.service';
import { DeviceType, Gender, IArrayItem, Role } from '../shared/shared.enum';

@Component({
  selector: 'app-diet-plan',
  templateUrl: './diet-plan.component.html',
  styleUrls: ['./diet-plan.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, display: 'none' }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DietPlanComponent implements OnInit, OnDestroy {
  protected _onDestroy = new Subject();
  form: FormGroup;
  countries: CountryResModel[] = [];
  dietPlannerTypes: LookupResModel[] = [];
  dietPlannerActivities: LookupResModel[] = [];
  dietPlannerCommunities: LookupResModel[] = [];
  dietPlannerPrefWorkoutTime: LookupResModel[] = [];
  dietPlannerFoodTypes: LookupResModel[] = [];
  dietPlannerDiseases: LookupResModel[] = [];
  dietPlannerAllergies: LookupResModel[] = [];
  dietPlanUrl?: string | null;
  genderArr: IArrayItem[] = [
    {
      title: 'Male',
      value: Gender.Male,
    },
    {
      title: 'Female',
      value: Gender.Female,
    },
  ];
  state = 1;
  heightFt: number = 0;
  heightInch: number = 0;
  heightFtArr: number[] = [];
  heightInchArr: number[] = [];
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries = new ReplaySubject<CountryResModel[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect?: MatSelect;

  constructor(
    private _lookupService: LookupService,
    private _authService: AuthService,
    private _countryService: CountryService,
    private _externalServiceBookingService: ExternalServiceBookingService,
    private _notificationService: NotificationService
  ) {
    this.form = new FormGroup({
      deviceType: new FormControl(DeviceType.Web, [Validators.required]),
      firstName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      mobile: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        NumericValidator,
      ]),
      email: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
        Validators.email,
      ]),
      password: new FormControl(this.generatePassword(10), [
        Validators.required,
      ]),
      corporate: new FormGroup({
        basicProfile: new FormGroup({
          dob: new FormControl(null, [Validators.required]),
          gender: new FormControl(null, [Validators.required]),
          heightInCm: new FormControl(null, [Validators.required]),
          weightInKg: new FormControl(null, [Validators.required]),
          countryId: new FormControl(null, [Validators.required]),
          preferredLanguage: new FormControl(1, [Validators.required]),
        }),
        dietPlannerProfile: new FormGroup({
          activity: new FormControl(null, [Validators.required]),
          diseases: new FormControl(null, []),
          allergies: new FormControl(null, []),
          prefWorkoutTime: new FormControl(null, [Validators.required]),
          community: new FormControl(null, [Validators.required]),
          foodType: new FormControl(null, [Validators.required]),
          dietType: new FormControl(null, [Validators.required]),
        }),
      }),
    });
  }

  ngOnInit() {
    this.fetchCountries();
    this.fetchDietPlannerPrefWorkoutTime();
    this.fetchDietPlannerCommunities();
    this.fetchDietPlannerFoodTypes();
    this.fetchDietPlannerActivities();
    this.fetchDietPlannerDiseases();
    this.fetchDietPlannerAllergies();
    this.fetchDietPlannerTypes();

    for (let i = 0; i <= 7; i++) {
      this.heightFtArr.push(i);
    }
    for (let i = 0; i <= 12; i++) {
      this.heightInchArr.push(i);
    }

    this.filteredCountries.next(this.countries.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  // Form error messages
  getErrorMessage(
    control: string,
    formGroup?: FormGroup,
    state: number = 0
  ): string | null {
    const form = formGroup ?? this.form;
    if (this.state == state || form.get(control)?.touched) {
      return FormErrorMessage(form, control);
    }
    return null;
  }

  filterCountries() {
    if (!this.countries) {
      return;
    }

    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(
        (country) => (country?.name ?? '').toLowerCase().indexOf(search) > -1
      )
    );
  }

  // Form
  getForm(control: string, from?: FormGroup): FormGroup {
    const form: FormGroup = from ?? this.form;

    return form.get(control) as FormGroup;
  }

  fetchDietPlannerDiseases(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'title')
      .append('OrderDirection', 'ASC')
      .append('Filter', `recordType:DietPlannerDiseases`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerDiseases = apiRes.data.list;
    });
  }

  fetchDietPlannerAllergies(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'title')
      .append('OrderDirection', 'ASC')
      .append('Filter', `recordType:DietPlannerAllergies`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerAllergies = apiRes.data.list;
    });
  }

  fetchDietPlannerPrefWorkoutTime(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      // .append('OrderBy', 'title')
      // .append('OrderDirection', 'ASC')
      .append('Filter', `recordType:DietPlannerPrefWorkoutTime`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerPrefWorkoutTime = apiRes.data.list;
    });
  }

  fetchDietPlannerCommunities(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      // .append('OrderBy', 'title')
      // .append('OrderDirection', 'ASC')
      .append('Filter', `recordType:DietPlannerCommunities`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerCommunities = apiRes.data.list;
    });
  }

  fetchDietPlannerFoodTypes(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      // .append('OrderBy', 'title')
      // .append('OrderDirection', 'ASC')
      .append('Filter', `recordType:DietPlannerFoodTypes`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerFoodTypes = apiRes.data.list;
    });
  }

  fetchDietPlannerTypes(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'title')
      .append('OrderDirection', 'ASC')
      .append('Filter', `recordType:DietPlannerTypes`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerTypes = apiRes.data.list;
    });
  }

  fetchDietPlannerActivities(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('Filter', `recordType:DietPlannerActivities`);
    this._lookupService.GetAll(param).subscribe((apiRes) => {
      this.dietPlannerActivities = apiRes.data.list;
    });
  }

  fetchCountries(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'name')
      .append('OrderDirection', 'ASC');
    this._countryService.GetAll(param).subscribe((apiRes) => {
      this.countries = apiRes.data.list;
      this.filterCountries();
    });
  }

  onSubmit() {
    this._authService.register(this.form.getRawValue()).subscribe((resp) => {
      setTimeout(() => {
        this.getUserAccounts();
      }, 1000);
    });
  }

  getUserAccounts(): void {
    this._authService.getUserAccounts().subscribe((apiRes) => {
      let corporates = apiRes.data;

      if (corporates.length == 1) {
        this.userGroupLogin(corporates[0].id);
      } else {
        var individualGroupId = corporates.find(
          (x) => x.corporate.role == Role.Individual
        )?.id;
        if (individualGroupId) this.userGroupLogin(individualGroupId);
      }
    });
  }

  userGroupLogin(userGroupId: string): void {
    this._authService
      .generateUserGroupToken(userGroupId)
      .subscribe((apiRes) => {
        setTimeout(() => {
          this.createBooking(userGroupId);
        }, 1000);
      });
  }

  createBooking(userGroupId: string): void {
    let bookingRequest = {
      UserGroupId: userGroupId,
      RecordType: 'DIET-PLANNER',
      RecordId: 1,
      Description: 'Diet Planning',
      BookingDate: new Date(),
    };
    this._externalServiceBookingService
      .Create(bookingRequest)
      .subscribe((res) => {
        this.getDietPlan(res.data);
      });
  }

  getDietPlan(bookingId: number): void {
    this._externalServiceBookingService
      .GetDietPlan(bookingId)
      .subscribe((res) => {
        this.dietPlanUrl = res.data;
      });
  }

  generatePassword(length: number = 10): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  calculateCm(): void {
    let totalCm = 0;
    totalCm += this.heightFt * 30.48;
    totalCm += this.heightInch * 2.54;

    this.getForm('basicProfile', this.getForm('corporate', this.form))
      ?.get('heightInCm')
      ?.setValue(parseInt(totalCm.toString()));
  }

  calculateFtInch(): void {
    let heightInCm = this.getForm(
      'basicProfile',
      this.getForm('corporate', this.form)
    )?.get('heightInCm')?.value;
    let totalCm = parseInt(heightInCm ?? '0');
    let totalFt = parseInt((totalCm / 2.54 / 12).toString());
    let totalInch = parseInt((totalCm / 2.54 - totalFt * 12).toString());

    this.heightFt = totalFt;
    this.heightInch = totalInch;
  }

  async isRegisteredUser(username: string): Promise<void> {
    const isRegisteredUser$ = this._authService.isRegisteredUser({
      username: username,
    });

    var res = await lastValueFrom(isRegisteredUser$);
    if (res.data) {
      this._notificationService.notify(
        'User with this details is already. Please login to take this service.'
      );
    } else {
      this.next();
    }
  }

  next() {
    ++this.state;
  }

  prev() {
    --this.state;
  }

  reset() {
    this.state = 1;
  }
}
