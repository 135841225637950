<div class="b2c-banner">
  <img src="assets/img/about_ban.jpg" class="img-fluid" />
  <div class="text_heading">
    <h2>B2C</h2>
  </div>
</div>

<div class="gradient">
  <div class="container">
    <div class="my-5 how-content-1">
      <div class="row">
        <div class="col-sm-5 order-1 order-md-1 center-content">
          <img class="rounded" src="assets/img/services/assessment.jpeg" />
        </div>
        <div class="col-sm-6 order-2 order-md-2 py-5">
          <h2>Dosha Assessment</h2>
          <div class="how-w-content">
            <p>
              Ayurvedic dosha assessment is a traditional Indian method of
              determining an individual's unique constitution and imbalances
              based on three primary doshas: Vata, Pitta, and Kapha. This
              assessment helps personalize diet, lifestyle, and wellness
              recommendations to promote physical and mental harmony, and to
              prevent and address health issues.
            </p>

            <div class="button-grp mt-4">
              <a routerLink="/dosha" class="tdr-button br">
                Check Your Dosha
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-7 order-2 order-md-1 py-5">
          <h2>Health Score</h2>
          <div class="how-w-content">
            <p>
              A health score for improving personal wellness is a numerical or
              qualitative measure used to assess an individual's overall health
              and well-being. It can include factors like physical fitness,
              nutrition, mental health, and lifestyle choices. Tracking and
              improving this score provides valuable insights for making
              informed decisions to enhance one's personal health and wellness.
            </p>

            <div class="button-grp mt-4">
              <a
                [href]="saasBaseUrl + '/open/organizational-assessment'"
                target="_blank"
                class="tdr-button br"
              >
                Get Your Health Score
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-1 order-md-2 center-content">
          <img class="rounded" src="assets/img/services/wellbe-score.jpeg" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-5 order-1 order-md-1 center-content">
          <img class="rounded" src="assets/img/services/diet-plan.jpeg" />
        </div>
        <div class="col-sm-7 order-2 order-md-2 py-5">
          <h2>AI enabled smart diet plan</h2>
          <div class="how-w-content">
            <p>
              An AI-enabled smart diet plan is a personalized and data-driven
              approach to nutrition that uses artificial intelligence to analyze
              individual health data, dietary preferences, and goals. It offers
              customized meal recommendations and adjustments, helping users
              make healthier food choices and achieve their specific health and
              fitness objectives more effectively.
            </p>

            <div class="button-grp mt-4">
              <a routerLink="/diet-plan" class="tdr-button br">
                Get Your AI Enabled Smart Diet Plan Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-7 order-2 order-md-1 py-5">
          <h2>Virtual Reality Exposure Therapy</h2>
          <div class="how-w-content">
            <p>
              Virtual Reality Exposure Therapy (VRET) is a therapeutic approach
              that uses virtual reality technology to immerse individuals in
              controlled and simulated environments, allowing them to confront
              and manage their fears, phobias, or anxieties. This exposure
              therapy technique is especially effective for conditions like
              post-traumatic stress disorder (PTSD), specific phobias, social
              anxiety, and other anxiety-related disorders. VRET provides a safe
              and controlled environment for individuals to gradually
              desensitize and build resilience to their triggers, ultimately
              reducing the intensity of their symptoms and improving their
              mental health and well-being.
            </p>

            <div class="button-grp mt-4">
              <a routerLink="/mental-wellness" class="tdr-button br">
                Explore Now
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-1 order-md-2 center-content">
          <img class="rounded" src="assets/img/services/vr-exposed.jpeg" />
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section">
  <div class="ex-course">
    <div class="container">
      <app-enquiry-form
        [enquiryAs]="'Distributor'"
        [enquiryFor]="'Distributor'"
      ></app-enquiry-form>
    </div>
  </div>
</section>

<section class="section gradient">
  <app-our-mobile-app></app-our-mobile-app>
</section>
