<div class="banner">
  <img src="assets/img/Services-1.png" class="img-fluid" />
  <div>
    <h2>Our Services</h2>
  </div>
</div>

<div class="our-story">
  <h2>Bespoke Solutions</h2>
</div>

<div class="how-works-content gradient">
  <div class="container">
    <div class="how-works mb-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                Customized corporate wellness programs based on Ayurveda and
                alternative medicine.
              </p>
              <p>
                Experts-led programs based on proven ancient therapies and
                treatments that drive results.
              </p>
              <p>
                In-depth analysis of the team health and data-driven plan
                development.
              </p>
              <p>
                Employee well-being initiatives tailored to individual needs and
                goals for tangible results.
              </p>
              <p>
                Individual goal-based plans and personal prescription packages
                are available.
              </p>
              <p>Group sessions for collective wellbeing of the team.</p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/BespokeSolutions-1.png" />
              <div class="how-w-img1-text"><h1>1</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-7 order-1">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/BespokeSolutions-2.png" />
              <div class="how-w-img1-text"><h1>2</h1></div>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-2">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                Employee well-being initiatives tailored to individual needs and
                goals for tangible results.
              </p>
              <p>
                Individual goal-based plans and personal prescription packages
                are available.
              </p>
              <p>Group sessions for collective wellbeing of the team.</p>
              <p>
                Expert guidance and support through group sessions and
                one-on-one consultations.
              </p>
              <p>
                Tap into a wide range of expertise covering all aspects of
                wellness like nutrition, NCDs, mental health, etc.
              </p>
              <p>Group intervention for employee engagement.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                Data-driven insights to measure outcomes and optimize wellness
                programs.
              </p>
              <p>
                CSR for employee wellness and fostering a culture of health,
                fitness and employee engagement.
              </p>
              <p>
                Continually evolving wellness program that accommodates your
                team’s changing health profile.
              </p>
              <p>
                Helping public health-focused enterprises deliver value through
                CSR making holistic wellness accessible.
              </p>
              <p>
                Regular reports shared through the HR dashboard for
                transparency.
              </p>
              <p>Give back to your community, starting with your own team.</p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="">
            <div class="how-w-img1">
              <img src="assets/img/BespokeSolutions-3.png" />
              <div class="how-w-img1-text"><h1>3</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <app-connect-with></app-connect-with>
    </div>
  </div>
</div>
