<section class="section gradient" id="section-anxiety-disorder">
  <div class="container">
    <section class="inner-banner">
      <div class="row">
        <div
          class="col-lg-7 my-3 d-flex justify-content-center align-items-center"
        >
          <img
            class="pill-image w-100"
            style="height: 450px"
            src="assets/img/mental-wellness/anxiety-disorder.avif"
            alt="banner-image"
          />
        </div>
        <div class="col-lg-5 my-3">
          <h1>Anxiety Disorder</h1>
          <p>
            Virtual Reality (VR) immersive therapy has shown great promise in
            the treatment of fears, phobias, and anxiety disorders. This
            innovative therapeutic approach capitalizes on the immersive and
            interactive nature of VR technology to create controlled
            environments that simulate the situations or triggers causing
            distress.
          </p>
          <p>
            Here's how VR immersive therapy can play a role in treating these
            conditions:
          </p>
          <div class="row features">
            <div class="col-md-6">
              <p><i class="fa fa-check-circle mr-2"></i> Exposure Therapy</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Realistic Simulations
              </p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Customizable
                Environments
              </p>
              <p><i class="fa fa-check-circle mr-2"></i> Safe Environment</p>
              <p><i class="fa fa-check-circle mr-2"></i> Emotion Regulation</p>
            </div>
            <div class="col-md-6">
              <p>
                <i class="fa fa-check-circle mr-2"></i> Biofeedback and
                Monitoring
              </p>
              <p><i class="fa fa-check-circle mr-2"></i> Generalization</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Engagement and
                Motivation
              </p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Variety of Applications
              </p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Convenience and
                Accessibility
              </p>
            </div>
          </div>
          <div class="button-grp mt-4">
            <a [href]="saasBaseUrl" target="_blank" class="tdr-button br">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="row" style="margin-top: 30px">
      <div class="col-12">
        <div class="text-center">
          <h3 class="title">Modules</h3>
          <p>
            Totally customized relaxation modules can also be delivered as per
            individual requirements.
          </p>
        </div>
      </div>
      <div
        class="col-lg-4"
        [ngClass]="{ 'my-3': i < 3 || (i >= 3 && showMoreServices) }"
        *ngFor="let module of modules; let i = index"
      >
        <div
          class="months p-3 rounded-pill d-flex align-items-center"
          *ngIf="i < 3 || (i >= 3 && showMoreServices)"
        >
          <div class="rounded-circle ml-3 p-3" style="overflow: hidden">
            <img [src]="module.img" width="60" height="60" />
          </div>
          <div class="ml-4">
            <h3>{{ module.title }}</h3>
            <p class="m-0">
              {{ module.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button
          class="btn btn-link"
          style="color: #766f6f"
          (click)="showMoreServices = !showMoreServices"
        >
          {{ showMoreServices ? "<< Less" : "More >>" }}
        </button>
      </div>
    </div>
  </div>
</section>
