<div class="banner">
  <img src="assets/img/Services-3.png" class="img-fluid" />
  <div>
    <h2>Our Services</h2>
  </div>
</div>

<div class="our-story">
  <h2>Tech-enabled Partnership</h2>
</div>

<div class="how-works-content gradient">
  <div class="container">
    <div class="how-works mb-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                <b> Improve Services</b>
              </p>
              <p>
                A tech-enabled partnership based on complete & comprehensive
                data sharing for creating better healthcare solutions &
                policies.
              </p>
              <p>
                <b>Data Driven Insights</b>
              </p>
              <p>
                Make data-informed underwriting decisions and personalized
                coverage options tailored to specific groups &, improve
                insurance offerings, and develop innovative products and
                services.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/TechEnabledPartnership-1.png" />
              <div class="how-w-img1-text"><h1>1</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-7 order-1">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/TechEnabledPartnership-2.png" />
              <div class="how-w-img1-text"><h1>2</h1></div>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-2">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                <b> Incentivized Wellness Programs</b>
              </p>
              <p>
                Promoting holistic wellness and preventive care through
                collaborative incentivized wellness programs integrated into
                Wellbe's solution that can track their wellness journeys.
              </p>
              <p>
                <b>Relevant Insurance Products</b>
              </p>
              <p>
                Tailored insurance plans addressing common health issues through
                comprehensive coverage options identified from Wellbe's
                wellbeing data analysis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                <b> Improve Risk Assessment</b>
              </p>
              <p>
                Precise risk profiling and proactive preventive measures based
                on valuable patient health data gathered through Wellbe's
                tech-enabled wellness solution.
              </p>
              <p>
                <b>Customer Engagement</b>
              </p>
              <p>
                Direct engagement with policyholders through Wellbe's platform,
                offering personalized health recommendations, wellness tips, and
                educational resources to enhance satisfaction and retention.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="">
            <div class="how-w-img1">
              <img src="assets/img/TechEnabledPartnership-3.png" />
              <div class="how-w-img1-text"><h1>3</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <app-connect-with></app-connect-with>
    </div>
  </div>
</div>
