<div class="gradient">
  <div class="container mt-5">
    <owl-carousel-o [options]="customOptions">
      <ng-template
        carouselSlide
        *ngFor="let item of bannerItems; let i = index"
      >
        <img
          class="d-block w-100"
          [class]="item.class"
          [src]="item.img"
          alt="First slide"
        />
        <div
          class="slider-text"
          [ngClass]="i % 2 == 0 ? 'slider-text-left' : 'slider-text-right'"
        >
          <h1 [style]="{ color: item.headingColor }">
            Virtual Reality for Mental Health
          </h1>
          <h4 [style]="{ color: item.quoteColor }">
            " <i>{{ item.desc }} </i>"
          </h4>

          <p
            class="mt-5 font-weight-bold color-secondary"
            [style]="{ color: item.headingColor }"
          >
            <a
              class="cursor-pointer mx-3"
              [style]="{ color: item.headingColor }"
              (click)="scrollToSection('section-anxiety-disorder')"
              >Anxiety Disorder</a
            >
            |<a
              class="cursor-pointer mx-3"
              [style]="{ color: item.headingColor }"
              (click)="scrollToSection('section-ptsd')"
            >
              PTSD</a
            >
            |
            <a
              class="cursor-pointer mx-3"
              [style]="{ color: item.headingColor }"
              (click)="scrollToSection('section-wellness')"
              >Wellness</a
            >

            <br />

            <a
              class="cursor-pointer mx-3"
              [style]="{ color: item.headingColor }"
              (click)="scrollToSection('section-videos')"
              >Watch Video</a
            >
            |
            <a
              class="cursor-pointer mx-3"
              [style]="{ color: item.headingColor }"
              (click)="scrollToSection('section-vr-headset')"
              >VR Headset</a
            >
            |
            <a
              class="cursor-pointer mx-3"
              [style]="{ color: item.headingColor }"
            >
              Try for Free</a
            >
          </p>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>

<div class="text-center my-4">
  <h1 class="color-navy-blue font-weight-bold">
    <img src="assets/img/quite.png" style="transform: rotate(180deg)" />
    Pioneer in Virtual Reality Immersive therapy for Mental Health in India
    <img src="assets/img/quite.png" />
  </h1>
</div>

<app-why-mental-health></app-why-mental-health>
<app-who-is-this-for></app-who-is-this-for>
<section class="mt-5">
  <div #PTSDSection>
    <app-ptsd #PTSDSection></app-ptsd>
  </div>
  <div #AnxietyDisorderSection>
    <app-anxiety-disorder></app-anxiety-disorder>
  </div>
  <div #WellnessSection>
    <app-wellness></app-wellness>
  </div>
</section>

<app-vr-headset></app-vr-headset>
<app-benefits></app-benefits>
<app-videos></app-videos>

<section class="gradient">
  <div class="container">
    <div class="contact-head2 text-center">
      <h1>1 on 1 Solutions to all your problems, by our service providers.</h1>
    </div>
  </div>
  <app-our-service-providers></app-our-service-providers>
  <app-our-mobile-app></app-our-mobile-app>
</section>
