import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnquiryService {
  constructor(private _httpClient: HttpClient) {}

  create(data: any): Observable<any> {
    return this._httpClient.post(
      `${environment.apiUrl}/g-core/v1/enquiry`,
      data
    );
  }
}
