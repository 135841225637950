<section class="gradient">
  <div class="our-associations">
    <div class="our-story">
      <h2>Our Associations</h2>
      <div class="mx-5 p-5 row">
        <div class="col-md-3 p-3" *ngFor="let item of associations">
          <div
            class="card h-100 d-flex align-items-center justify-content-center p-5"
            style="border-radius: 20px"
          >
            <img [style.width]="item.width" [src]="item.imageSrc" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="our-associations">
    <div class="our-story py-0">
      <h2>Our Partners</h2>
      <div class="mx-5 p-5 row">
        <div class="col-md-3 p-3" *ngFor="let item of partners">
          <div
            class="card h-100 d-flex align-items-center justify-content-center p-5"
            style="border-radius: 20px"
          >
            <img [style.width]="item.width" [src]="item.imageSrc" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-us">
    <h1>Connect with us</h1>
    <a
      class="btn"
      href="https://calendly.com/dr-ksiddharth/product-demo"
      target="_blank"
    >
      Schedule a call
    </a>
  </div>
</section>
