import { HttpParams } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { CountryResModel } from 'src/app/models/country.model';
import { CountryService } from 'src/app/serivce/country.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { EnquiryService } from '../../serivce/enquiry.service';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss'],
})
export class EnquiryFormComponent implements OnInit, OnChanges, OnDestroy {
  protected _onDestroy = new Subject();
  @Input() enquiryAs: any;
  @Input() enquiryFor: any;
  isSubmitted = false;
  enquiryReCaptcha: string = '';
  countries: CountryResModel[] = [];
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries = new ReplaySubject<CountryResModel[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect?: MatSelect;

  form = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    mobile: new FormControl(null, [Validators.required]),
    country: new FormControl(null, [Validators.required]),
    queriedAs: new FormControl(null, [Validators.required]),
    queriedFor: new FormControl(null, [Validators.required]),
    query: new FormControl(null, [Validators.required]),
  });

  constructor(
    private _countryService: CountryService,
    private _enquiryService: EnquiryService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.fetchCountries();

    this.filteredCountries.next(this.countries.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  ngOnChanges(): void {
    this.form.get('queriedAs')?.setValue(this.enquiryAs);
    this.form.get('queriedFor')?.setValue(this.enquiryFor);
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  verifyEnquiryCaptcha(captchaRef: any): void {
    if (this.form.valid) {
      if (this.enquiryReCaptcha) {
        captchaRef.reset();
      }
      captchaRef.execute();
    }
  }

  enquiryCaptchaResolved(captchaResponse: string): void {
    this.enquiryReCaptcha = captchaResponse;
    if (this.enquiryReCaptcha) {
      this.submitEnquiry();
    }
  }

  filterCountries() {
    if (!this.countries) {
      return;
    }

    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(
        (country) => (country?.name ?? '').toLowerCase().indexOf(search) > -1
      )
    );
  }

  fetchCountries(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'name')
      .append('OrderDirection', 'ASC');
    this._countryService.GetAll(param).subscribe((apiRes) => {
      this.countries = apiRes.data.list;
      this.filterCountries();
    });
  }

  submitEnquiry(): void {
    if (!this.form.valid) return;

    this._enquiryService.create(this.form.getRawValue()).subscribe(() => {
      this.form.reset();

      this._notificationService.notify('Enquiry submitted successfully');
    });
  }
}
