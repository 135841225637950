import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';
import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatRadioModule } from '@angular/material/radio';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { environment } from 'src/environments/environment';
import { AboutusComponent } from './aboutus/aboutus.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';
import { DoshaAssessmentCommonComponent } from './component/assessment/assessment.component';
import { ConnectWithComponent } from './component/connect-with-us/connect-with.component';
import { EnquiryFormComponent } from './component/enquiry-form/enquiry-form.component';
import { NewsletterComponent } from './component/newsletter/newsletter.component';
import { OurClientsComponent } from './component/our-clients/our-clients.component';
import { OurMobileAppComponent } from './component/our-mobile-app/our-mobile-app.component';
import { OurServiceProvidersAllComponent } from './component/our-service-providers-all/our-service-providers-all.component';
import { OurServiceProvidersComponent } from './component/our-service-providers/our-service-providers.component';
import { OurSolutionsComponent } from './component/our-solutions/our-solutions.component';
import { StoryboardComponent } from './component/storyboard/storyboard.component';
import { TestimonialsComponent } from './component/testimonials/testimonials.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateComponent } from './corporate/corporate.component';
import { DietPlanComponent } from './diet-plan/diet-plan.component';
import { DistributorComponent } from './distributor/distributor.component';
import { DoshaComponent } from './dosha/dosha.component';
import { HomeComponent } from './home/home.component';
import { IndividualComponent } from './individual/individual.component';
import { AnxietyDisorderComponent } from './mental-wellness/anxiety-disorder/anxiety-disorder.component';
import { BenefitsComponent } from './mental-wellness/benefits/benefits.component';
import { MentalWellnessComponent } from './mental-wellness/mental-wellness.component';
import { PtsdComponent } from './mental-wellness/ptsd/ptsd.component';
import { VideosComponent } from './mental-wellness/videos/videos.component';
import { VrHeadsetComponent } from './mental-wellness/vr-headset/vr-headset.component';
import { WellnessComponent } from './mental-wellness/wellness/wellness.component';
import { WhoIsThisForComponent } from './mental-wellness/who-is-this-for/who-is-this-for.component';
import { WhyMentalHealthComponent } from './mental-wellness/why-mental-health/why-mental-health.component';
import { PartnersComponent } from './partners/partners.component';
import { PractitionersComponent } from './practitioners/practitioners.component';
import { B2bComponent } from './services/b2b/b2b.component';
import { B2cComponent } from './services/b2c/b2c.component';
import { BespokeSolutionsComponent } from './services/bespoke-solutions/bespoke-solutions.component';
import { RevolutionaryTherapiesComponent } from './services/revolutionary-therapies/revolutionary-therapies.component';
import { SpecializedPersonalizedComponent } from './services/specialized-personalized/specialized-personalized.component';
import { TechEnabledPartnershipComponent } from './services/tech-enabled-partnership/tech-enabled-partnership.component';
import { AuthorizationInterceptor } from './shared/interceptor.service';
import { SafePipe } from './utils/safe.pipe';

export const RecaptchaGlobalSettings: RecaptchaSettings = {
  siteKey: environment.recaptchaKey,
};
@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    HomeComponent,
    AboutusComponent,
    IndividualComponent,
    CorporateComponent,
    ContactUsComponent,
    HeaderComponent,
    FooterComponent,
    TestimonialsComponent,
    OurSolutionsComponent,
    OurClientsComponent,
    OurMobileAppComponent,
    OurServiceProvidersComponent,
    OurServiceProvidersAllComponent,
    BespokeSolutionsComponent,
    RevolutionaryTherapiesComponent,
    SpecializedPersonalizedComponent,
    TechEnabledPartnershipComponent,
    EnquiryFormComponent,
    ConnectWithComponent,
    PractitionersComponent,
    NewsletterComponent,
    MentalWellnessComponent,
    WhyMentalHealthComponent,
    WhoIsThisForComponent,
    PtsdComponent,
    AnxietyDisorderComponent,
    WellnessComponent,
    BenefitsComponent,
    VrHeadsetComponent,
    VideosComponent,
    StoryboardComponent,
    DoshaComponent,
    DietPlanComponent,
    PartnersComponent,
    DistributorComponent,
    B2bComponent,
    B2cComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CarouselModule,
    SlickCarouselModule,
    DoshaAssessmentCommonComponent,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatNativeDateModule,
    RecaptchaModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: RecaptchaGlobalSettings,
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([AuthorizationInterceptor])
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
