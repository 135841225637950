export enum DeviceType {
  Web = 1,
  Android,
  iOS,
}

export enum Role {
  SuperAdmin = 1,
  Tenant,
  Hospital,
  Corporate,
  ServiceProvider,
  Employee,
  Individual,
}

export enum Gender {
  Male = 1,
  Female,
}

export interface IArrayItem {
  title: string;
  value: any;
}
