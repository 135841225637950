import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-mobile-app',
  templateUrl: './our-mobile-app.component.html',
  styleUrls: ['./our-mobile-app.component.scss'],
})
export class OurMobileAppComponent {
  saasBaseUrl = environment.saasBaseUrl;
}
