import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  saasBaseUrl = environment.saasBaseUrl;
  watchVideo = false;

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };

  services: { label: string; imageSrc: string; routerLink?: string }[] = [
    {
      label: 'Yoga',
      imageSrc: 'assets/img/yoga.png',
    },
    {
      label: 'Reiki',
      imageSrc: 'assets/img/reiki.png',
    },
    {
      label: 'Ayurveda',
      imageSrc: 'assets/img/ayurveda.png',
    },
    {
      label: 'Nutrition',
      imageSrc: 'assets/img/nutrition.png',
    },
    {
      label: 'Guided Meditation',
      imageSrc: 'assets/img/nutrition.png',
    },
    {
      label: 'Water therapy',
      imageSrc: 'assets/img/nutrition.png',
    },
    {
      label: 'Sunlight therapy',
      imageSrc: 'assets/img/nutrition.png',
    },
    {
      label: 'Hand mudra therapy',
      imageSrc: 'assets/img/nutrition.png',
    },
  ];
}
