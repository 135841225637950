<div class="gradient">
  <div class="container">
    <div
      id="carouselExampleControls"
      class="carousel slide indi-slider"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="d-block w-100"
            src="assets/img/Group 222.png"
            alt="First slide"
          />
          <div class="slider-text"><h1>Your health in your hands</h1></div>
        </div>
        <!-- <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/img/Group 222.png"
            alt="Second slide"
          />
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/img/Group 222.png"
            alt="Third slide"
          />
        </div> -->
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="" aria-hidden="true"
          ><i class="fa-solid fa-arrow-left"></i
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="" aria-hidden="true"
          ><i class="fa-solid fa-arrow-right"></i
        ></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>

<div class="contact-with">
  <div class="container">
    <div class="contact-head">
      <h1>
        Customize your package according to your needs and preferences. See the
        results you want.
      </h1>
    </div>
    <app-connect-with></app-connect-with>
  </div>
</div>

<div class="how-works-content gradient">
  <div class="container">
    <div class="how-works mb-5 how-content-1">
      <div class="row">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <h2>How it works</h2>
            <div class="how-w-content">
              <p>Initial Dosha Assessment</p>
              <p>
                Our in-depth dosha assessment helps you understand your body
                better, detect early signs of illness and deep-seated health
                issues.
              </p>
            </div>
            <div>
              <button class="how-btn btn" routerLink="/dosha">
                Check Your Dosha
              </button>
            </div>
          </div>
          <div class="how-w-left">
            <div class="how-w-content">
              <p>Early Detection</p>
              <p>
                our tech-enabled early detection helps you identify markers of
                non-communicable diseases and chronic illnesses.
              </p>
            </div>
            <div>
              <button class="how-btn btn">Early Detection Assessment</button>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/how-img-1.png" />
              <div class="how-w-img1-text"><h1>1</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5 how-content-2">
      <div class="row">
        <div class="col-sm-7 order-1">
          <div class="how-w-2">
            <div class="how-w-img1">
              <img src="assets/img/how-img-2.png" />
              <div class="how-w-img1-text"><h1>2</h1></div>
              <!-- <div class="how-w-vec1">
                <img src="assets/img/Vector 4.png" />
              </div>
              <div class="how-w-vec2">
                <img src="assets/img/Vector 3.png" />
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-2">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>Consultation and prescription</p>
              <p>
                Our advanced AI-based algorithm analyzes your mind-body type and
                your health goals and matches you to the most-suitable service
                provider. After a thorough consultation, you receive a
                downloadable prescription. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5 how-content-3">
      <div class="row">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>Customize package or talk to wellness buddy</p>
              <p>
                Based on the prescription, you can customize your package and
                add, remove and edit any service from any category. Our Wellness
                Buddy is available 24*7 to assist you with getting the best
                package to achieve your unique health goals. 
              </p>
              <p>And repeat.</p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="">
            <div class="how-w-img1">
              <img src="assets/img/how-img-3.png" />
              <div class="how-w-img1-text"><h1>3</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="get-btns">
      <div></div>
      <div class="get-app-btn">
        <button class="get-btn btn">Get App</button>
      </div>
      <div class="">
        <a
          [href]="saasBaseUrl + '/sign-in?redirectURL=/webapp/assessment'"
          target="_blank"
          class="take-ass-btn get-btn btn"
        >
          Take Assessment
        </a>
        <div class="btn-hr"></div>
      </div>
    </div>
  </div>
</div>

<section class="section gradient">
  <div class="ex-course">
    <div class="container">
      <div class="contact-head2 text-center">
        <h1>Explore our self-improvement courses</h1>
      </div>

      <div class="mx-5 p-5">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of courses">
            <ng-template carouselSlide>
              <img [src]="item.imageSrc" />
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
  <div class="servc-providers">
    <div class="container">
      <div class="contact-head2 text-center">
        <h1>
          1 on 1 Solutions to all your problems, by our service providers.
        </h1>
      </div>
    </div>
    <app-our-service-providers
      [page]="'individual'"
    ></app-our-service-providers>
    <div class="container">
      <div class="text-right">
        <a routerLink="/practitioners" class="btn more">More</a>
      </div>
    </div>
  </div>

  <app-our-mobile-app></app-our-mobile-app>
</section>
