import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-anxiety-disorder',
  templateUrl: './anxiety-disorder.component.html',
  styleUrls: ['./anxiety-disorder.component.scss'],
})
export class AnxietyDisorderComponent {
  saasBaseUrl = environment.saasBaseUrl;
  showMoreServices = false;
  modules: {
    title: string;
    description: string;
    img: string;
    link?: string;
  }[] = [
    {
      title: 'Heights',
      description: 'Acrophobia',
      img: 'assets/img/anxiety-disorder/acrophobia-psytechvr.svg',
    },
    {
      title: 'Being alone',
      description: 'Autophobia',
      img: 'assets/img/anxiety-disorder/Autophobia.svg',
    },
    {
      title: 'Public speaking',
      description: 'Glossophobia',
      img: 'assets/img/anxiety-disorder/glossophobia-psytech.svg',
    },
    {
      title: 'Airport, airplane, flight',
      description: 'Aerophobia',
      img: 'assets/img/anxiety-disorder/aerophobia-psytechvr.svg',
    },
    {
      title: 'Driving',
      description: 'Amaxophobia',
      img: 'assets/img/anxiety-disorder/Amaxophobia.svg',
    },
    {
      title: 'Storm, flood',
      description: 'Anemophobia',
      img: 'assets/img/anxiety-disorder/Anemophobia.svg',
    },
    {
      title: 'Spiders',
      description: 'Arachnophobia',
      img: 'assets/img/anxiety-disorder/arachnophobia-psytec.svg',
    },
    {
      title: 'Enclosed spaces',
      description: 'Claustrophobia',
      img: 'assets/img/anxiety-disorder/claustrophobia-psyte.svg',
    },
    {
      title: 'Bridges and tunnels',
      description: 'Gephyrophobia',
      img: 'assets/img/anxiety-disorder/Gephyrophobia.svg',
    },
    {
      title: 'Dogs',
      description: 'Cynophobia',
      img: 'assets/img/anxiety-disorder/cynophobia-psytechvr.svg',
    },
    {
      title: 'Office & work',
      description: 'Ergophobia',
      img: 'assets/img/anxiety-disorder/Ergophobia.svg',
    },
    {
      title: 'Crowd',
      description: 'Enochlophobia',
      img: 'assets/img/anxiety-disorder/Enochlophobia.svg',
    },
    {
      title: 'Blood',
      description: 'Hemophobia',
      img: 'assets/img/anxiety-disorder/Hemophobia.svg',
    },
    {
      title: 'Doctors',
      description: 'Iatrophobia',
      img: 'assets/img/anxiety-disorder/Iatrophobia.svg',
    },
    {
      title: 'Cockroaches, flies etc.',
      description: 'Insectophobia',
      img: 'assets/img/anxiety-disorder/insectophobia-psytec.svg',
    },
    {
      title: 'Unsanitary condition',
      description: 'Mysophobia',
      img: 'assets/img/anxiety-disorder/mysophobia-psytechvr.svg',
    },
    {
      title: 'Bacteria, germs',
      description: 'Germophobia',
      img: 'assets/img/anxiety-disorder/germophobia-psytechv.svg',
    },
    {
      title: 'Hospitals',
      description: 'Nosocomephobia',
      img: 'assets/img/anxiety-disorder/Nosocomephobia.svg',
    },
    {
      title: 'Schools',
      description: 'Scolionophobia',
      img: 'assets/img/anxiety-disorder/Scolionophobia.svg',
    },
    {
      title: 'Needles',
      description: 'Trypanophobia',
      img: 'assets/img/anxiety-disorder/trypanophobia.svg',
    },
    {
      title: 'Subway, train',
      description: 'Siderodromophobia',
      img: 'assets/img/anxiety-disorder/Siderodromophobia.svg',
    },
    {
      title: 'Travel, transport',
      description: 'Hodophobia',
      img: 'assets/img/anxiety-disorder/Hodophobia.svg',
    },
    {
      title: 'Darkness & night',
      description: 'Nyctophobia',
      img: 'assets/img/anxiety-disorder/nyctophobia-psytechv.svg',
    },
  ];
}
