<div class="banner">
  <img src="assets/img/Services-2.png" class="img-fluid" />
  <div>
    <h2>Our Services</h2>
  </div>
</div>

<div class="our-story">
  <h2>Revolutionary Therapies</h2>
</div>

<div class="how-works-content gradient">
  <div class="container">
    <div class="how-works mb-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                B2B software licence for state-of-the-art Virtual Reality
                Exposure Therapy toolkit.
              </p>
              <p>
                Create immersive and interactive environments for therapeutic
                purposes.
              </p>
              <p>
                300-500 sqft. VR Relaxation Experience Centre set-up for
                expanding your services around mental health support.
              </p>
              <p>
                Revolutionary VR therapy for mental wellness complete with
                technical support and on-ground activation assistance.
              </p>
              <p>
                Increased footfalls in the facility filling up a major gap in
                the category through mental health services.
              </p>
              <p>
                Increased exposure to patients suffering from comorbidities
                arising from mental health issues.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/RevolutionaryTherapies-1.png" />
              <div class="how-w-img1-text"><h1>1</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-7 order-1">
          <div class="how-w-1">
            <div class="how-w-img1">
              <img src="assets/img/RevolutionaryTherapies-2.png" />
              <div class="how-w-img1-text"><h1>2</h1></div>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-2">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                Data analytics to measure the impact of holistic approaches on
                patient outcomes.
              </p>
              <p>
                Customized wellness plans for hospital staff to enhance
                wellbeing and reduce burnout.
              </p>
              <p>Asset-light solutions with high scalability.</p>
              <p>
                Tap into a wide range of expertise covering all aspects of
                wellness like nutrition, NCDs, mental health, etc.
              </p>
              <p>Open doors to corporate wellness program opportunities.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-works my-5">
      <div class="row align-items-center">
        <div class="col-sm-5 order-2 order-md-1">
          <div class="how-w-left">
            <div class="how-w-content">
              <p>
                Get an integrated approach to patient care: a cutting-edge
                physical set-up and on-demand online immersive experience
                hand-in-hand.
              </p>
              <p>
                Treating deep-seated psychological issues and phobias in record
                time and with minimal doctor intervention leading to better
                patient care experience.
              </p>
              <p>
                Versatile and robust platform with 24x7x365 support
                availability.
              </p>
              <p>
                Solutions supplemented by Ayurvedic therapies and herbal
                products, natural supplements, superfoods, etc. for augmenting
                the holistic experience and patient wellbeing.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-7 order-1 order-md-2">
          <div class="">
            <div class="how-w-img1">
              <img src="assets/img/RevolutionaryTherapies-3.png" />
              <div class="how-w-img1-text"><h1>3</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <app-connect-with></app-connect-with>
    </div>
  </div>
</div>
