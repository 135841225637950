<div class="container mt-4">
  <div class="row">
    <div class="col-md-12">
      <h1 class="title">What our clients say</h1>
      <div class="mt-5">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of testimonials">
            <ng-template carouselSlide>
              <div class="p-2">
                <div class="testimonial-box">
                  <h4>{{ item.service }}</h4>
                  <p class="msg">
                    {{ item.testimonial }}
                  </p>
                  <div class="auther-info">
                    <div class="mr-2">
                      <!-- <img [src]="item.userImg" alt="user image" /> -->
                      <i class="fa fa-user-circle"></i>
                    </div>
                    <div>
                      <p class="font-weight-bold">{{ item.userName }}</p>
                      <p>{{ item.userDesignation }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
