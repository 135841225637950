import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { IScore } from '../component/assessment/assessment.component';
import { DoshaService } from '../serivce/dosha.service';

@Component({
  selector: 'app-dosha',
  templateUrl: './dosha.component.html',
  styleUrls: ['./dosha.component.scss'],
})
export class DoshaComponent {
  score: IScore = {
    total: 0,
    vata: 0,
    pitta: 0,
    kapha: 0,
    mindBodyType: '',
  };
  isSubmitted = false;
  doshaReCaptcha: string = '';
  part = 1;
  form: FormGroup;

  constructor(private _doshaService: DoshaService) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      mobile: new FormControl(null, [Validators.required]),
    });
  }

  verifyDoshaCaptcha(captchaRef: any): void {
    if (this.form.valid) {
      if (this.doshaReCaptcha) {
        captchaRef.reset();
      }
      captchaRef.execute();
    }
  }

  doshaCaptchaResolved(captchaResponse: string): void {
    this.doshaReCaptcha = captchaResponse;
    if (this.doshaReCaptcha) {
      this.submit();
    }
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  submit(): void {
    if (!this.form.valid) return;
    this._doshaService
      .create(this.form.value)
      .pipe(
        finalize(() => {
          this.form.enable();
        })
      )
      .subscribe((apiRes) => {
        this.part = 3;
      });
  }
}
