import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiListResponse } from '../models/api-res.model';
import { CountryResModel } from '../models/country.model';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private _httpClient: HttpClient) {}

  GetAll(param: HttpParams): Observable<IApiListResponse<CountryResModel>> {
    return this._httpClient.get<IApiListResponse<CountryResModel>>(
      `${environment.apiUrl}/g-master/v1.0/country`,
      { params: param }
    );
  }
}
