import { Component, Input, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-our-service-providers',
  templateUrl: './our-service-providers.component.html',
  styleUrls: ['./our-service-providers.component.scss'],
})
export class OurServiceProvidersComponent implements OnInit {
  @Input() page: string = 'individual';

  slides: {
    items: { img: string; name: string; about: string }[];
    class?: string;
  }[][] = [
    [
      {
        items: [
          {
            img: 'assets/img/s-1.png',
            name: 'Khushboo',
            about:
              'With almost a decade of event hosting experience worldwide, Khushboo is a versatile anchor who transforms events into unforgettable experiences. Her charisma, enthusiasm, and yoga expertise make her an exceptional choice for a variety of occasions, merging glamour with spirituality.',
          },
        ],
        class: 'large-shadow',
      },
      {
        items: [
          {
            img: 'assets/img/s-2.png',
            name: 'Dr. Kishlay',
            about:
              'Dr. Kishlay, known as the "Metabolic Doctor," is an MD at TATA Memorial Hospital, renowned for his innovative approach to treating severe cases with non-medicated, lifestyle adjustments. He champions the concept of "Anti Inflammatory lifestyle" and believes in the potential for disease remission through disciplined diet and exercise.',
          },
          {
            img: 'assets/img/s-3.png',
            name: 'Dr. Deepak Sharma',
            about:
              "With over 30 years of experience as an Ayurvedic Physician, Dr. Deepak Sharma is a renowned figure in Delhi. He's known for his positive approach, vast expertise, and transformative impact on patients' lives through Ayurveda. As Managing Director of Rasayanshala, he's dedicated to both practice and spreading Vedic Sciences knowledge.",
          },
        ],
        class: 'small-shadow',
      },
      {
        items: [
          {
            img: 'assets/img/s-4.png',
            name: 'Dr. Virje Bahai Khosla',
            about:
              "Dr. Virje Bahai Khosla is a visionary in Traditional Indian medicine and Yoga, leading the European Forum of Ayurveda and Yoga as Chairman. With extensive clinical practice and training, he's a pioneer in Yoga, Naturopathy, and Ayurveda, renowned for decoding and enhancing traditional remedies for modern needs.",
          },
        ],
        class: 'small-shadow',
      },
      {
        items: [
          {
            img: 'assets/img/s-5.png',
            name: 'Anil Bhatnagar',
            about:
              'Anil Bhatnagar is a multifaceted personality: a corporate trainer for 125+ global companies, award-winning author, artist, and neuroscience enthusiast, with appearances as an expert on national television and contributions to prominent magazines. Graduated from IIT Delhi, he embarked on a unique journey after leaving his corporate job in 2001.',
          },
          {
            img: 'assets/img/s-6.png',
            name: 'Amitabh Swetta',
            about:
              'Amitabh Swetta, a Corporate Trainer, healer, and Coach with 25+ years of experience, specialises in holistic healing for mental health, stress, and sleep issues. Founder of 1000 Petals, he integrates innovative techniques like Floatation tanks and Lucia no03 light meditation to enhance sleep, reduce stress, and boost productivity.',
          },
        ],
        class: 'small-shadow',
      },
      {
        items: [
          {
            img: 'assets/img/s-7.png',
            name: 'Dr. RS Dabas',
            about:
              'Dr. RS Dabas is a globally recognized Corporate Trainer, Motivational Speaker, and HR Expert, renowned for transforming organisations and individuals through innovative training and coaching. With an impressive track record in leadership roles across esteemed companies, he has championed cultural transformations, leadership development, and remarkable EBITA improvements.',
          },
        ],
        class: 'small-shadow',
      },
    ],
  ];

  slideConfig = {
    arrows: false,
    dots: false,
    fade: false,
    infinite: true,
    autoplay: true,
    // centerMode: true,
    speed: 300,
  };

  ngOnInit(): void {
    $(document).ready(function () {
      $('[data-toggle="popover"]').popover({
        placement: 'top',
        trigger: 'hover',
      });
    });
  }
}
