<section class="inner-banner">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5 p-0">
        <div class="inner-banner-text">
          <h1>44% of your employees are at risk of diabetes</h1>
          <p>
            Improve their health and productivity at work with holistic wellness
            amd transform your employees into your best assets. Our
            enterprise-centric solutions help you measure the impact of your
            team’s improved health on your bottom-line.
          </p>
          <div class="button-grp">
            <button class="tdr-button br" (click)="scroll(EnquiryForm)">
              Get Started
            </button>
            <a
              (click)="scroll(WellnessJourney)"
              class="tdr-button sec cursor-pointer"
            >
              How it Works?
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-7 p-0">
        <img src="assets/img/inner-banner.png" alt="banner-image" />
      </div>
    </div>
  </div>
</section>

<section class="section gradient">
  <app-storyboard></app-storyboard>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="title text-center">Benefits</h1>
      </div>
    </div>
    <div class="row benefits">
      <div class="col-md-6 col-12">
        <div class="nbr-box">
          <span>1</span>
          <h3>Increased Productivity</h3>
          <p>
            Studies have shown that employees who are physically fit are more
            productive than those who are not. This is likely due to the fact
            that fit employees have more energy and are less likely to get sick.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="nbr-box">
          <span>2</span>
          <h3>Reduced Absenteeism</h3>
          <p>
            Fit employees are less likely to miss work due to illness. This can
            save companies money on healthcare costs and lost productivity.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="nbr-box">
          <span>3</span>
          <h3>Measurable ROI</h3>
          <p>
            Fit employees are more likely to be happy and engaged in their work.
            This can lead to increased creativity and innovation, lending
            directly to the bottom-line.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="nbr-box">
          <span>4</span>
          <h3>Low Cost Insurance</h3>
          <p>
            By reducing the likelihood of chronic illness and non-communicable
            diseases, organizations spend less on medical insurance and hospital
            claims. 
          </p>
        </div>
      </div>
    </div>
    <div class="row health">
      <div class="col-md-7 col-12">
        <div class="health-box sec">
          <img src="assets/img/phone-boy.png" alt="yoga girl" />
          <img src="assets/img/health-summary2.png" alt="yoga girl" />
        </div>
      </div>
      <div class="col-md-5 col-12">
        <div class="awantika-box">
          <img src="assets/img/awantika.png" alt="awantika" />
          <a routerLink="/bespoke-solutions">
            <img src="assets/img/explore.png" alt="explore" />
          </a>
        </div>
      </div>
    </div>
    <div class="row mb-4 mt-2" #WellnessJourney>
      <div class="col-md-12">
        <h1 class="sub-title text-center">Organizational Wellness journey</h1>
      </div>
    </div>
    <div class="row journey">
      <div class="col-md-4 col-12">
        <div class="months">
          <h3>0-3</h3>
          <span>Months</span>
          <p>
            In-depth health analysis to determine most prominent health issues
            among the team members.
          </p>
          <p>
            Reports on the current health status and customized plan for the
            collective fitness of the team.
          </p>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="months">
          <h3>3-9</h3>
          <span>Months</span>
          <p>
            One-to-one and group interventions with package customization
            available for individual needs and goals.
          </p>
          <p>
            Constant monitoring and employee engagement aimed at improving the
            overall Health Score and productivity.
          </p>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="months">
          <h3>9-12</h3>
          <span>Months</span>
          <p>
            Measure the impact of the wellness program on Health Scores and team
            productivity.
          </p>
          <p>
            Update the custom wellness program further to continue on the
            trajectory of better quality of life.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section gradient">
  <div class="container">
    <div class="row mb-4 mt-4">
      <div class="col-md-12">
        <div class="col-md-12">
          <h1 class="sub-title">
            Keep a finger on the pulse of your team. Literally. 
          </h1>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 col-12 list-style">
        <ul>
          <li>Measure your employees’ health through advanced metrics.</li>
          <li>
            Track the ROI and the impact of our services on your bottomline.
          </li>
          <li>
            Set it and forget it – we take care of your employees so that you
            can focus on more important tasks.
          </li>
        </ul>
        <p>
          Our data-driven approach to prioritizing employee wellness helps you
          clearly map its impact on the team’s productivity. Get the complete
          picture of your employees health for results that really matter.
        </p>
      </div>
      <div class="col-md-6 col-12 bg-grade">
        <img src="assets/img/couple-img.png" />
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-md-12 text-center">
        <a
          href="https://calendly.com/dr-ksiddharth/product-demo"
          target="_blank"
          class="get-btn btn"
          >Book an Appointment</a
        >

        <h4 class="sub-title-sec">
          Employee wellbeing packages across ages and generations for better
          growth of the enterprise.
        </h4>
      </div>
    </div>
  </div>
  <section class="section">
    <app-testimonials></app-testimonials>
  </section>
  <section class="section">
    <app-our-mobile-app></app-our-mobile-app>
  </section>

  <section class="section">
    <div class="container">
      <div class="row approach mx-5">
        <div class="col-md-12 text-center">
          <h1 class="title">Our Approach</h1>
          <p>
            We are constantly evolving our solutions to make fitness
            personalized, inclusive and accessible for all. 
          </p>
          <div class="bg-grade absolute"></div>
          <img src="assets/img/gmail.png" />
          <h4>Mental Wellbeing through virtual reality exposure therapy.</h4>
          <a routerLink="/mental-wellness">More</a>
        </div>
      </div>
      <div #EnquiryForm>
        <app-enquiry-form [enquiryAs]="'corporate'"></app-enquiry-form>
      </div>
    </div>
  </section>
</section>
