<div class="b2b-banner">
  <img src="assets/img/about_ban.jpg" class="img-fluid" />
  <div class="text_heading">
    <h2>B2B</h2>
  </div>
</div>

<div class="gradient">
  <div class="container">
    <div class="my-5 how-content-1">
      <div class="row">
        <div class="col-sm-5 order-1 order-md-1 center-content overflow-hidden">
          <img
            class="rounded"
            src="assets/img/services/ayurveda.jpeg"
            style="transform: scale(1.5)"
          />
        </div>
        <div class="col-sm-6 order-2 order-md-2 py-5">
          <h2>Ayurveda/Naturopathy</h2>
          <div class="how-w-content">
            <p>
              A B2B corporate wellness subscription plan based on the Ayurvedic
              system/Naturopathy is a holistic and preventive approach to
              enhancing the well-being of employees within a corporate setting.
              Ayurveda, an ancient Indian system of medicine, focuses on the
              balance of mind, body, and spirit, and it emphasizes personalized
              wellness.
            </p>
            <p>
              In this subscription plan, WellBe partners with Ayurvedic wellness
              experts and providers to offer tailored wellness solutions to
              employees. The plan typically includes:
            </p>
            <p>
              <b>Personalized Wellness Assessments:</b> Employees receive
              individual health assessments based on Ayurvedic principles. These
              assessments consider each person's dosha (body constitution) and
              imbalances, helping to identify areas of health improvement.
            </p>
            <p>
              <b>Diet and Nutrition Guidance:</b> Ayurveda places great
              importance on dietary choices to maintain balance and health.
              Employees receive guidance on Ayurvedic dietary principles and
              customized meal plans to support their unique constitution.
            </p>
            <p>
              <b>Yoga and Mindfulness:</b> Incorporating yoga and mindfulness
              practices from Ayurveda can help reduce stress and promote mental
              well-being. Employees may have access to yoga classes, meditation
              sessions, or stress-reduction programs.
            </p>
            <p>
              <b>Herbal Remedies and Supplements:</b> Ayurveda often uses
              natural herbs and supplements to address health issues. Employees
              may be provided with Ayurvedic herbal remedies to support their
              well-being.
            </p>
            <p>
              <b> Lifestyle Recommendations:</b> Ayurvedic wellness plans also
              offer lifestyle recommendations, including sleep hygiene, daily
              routines (dinacharya), and exercise tailored to an individual's
              dosha.
            </p>
            <p>
              <b>Regular Check-ins and Monitoring:</b> Employees benefit from
              ongoing support and check-ins to track their progress and make
              necessary adjustments to their wellness plans.
            </p>
            <p>
              <b>Educational Workshops:</b> Companies may arrange workshops or
              seminars on Ayurveda and its principles to educate employees about
              this holistic approach to health.
            </p>

            <div class="button-grp mt-4">
              <a
                href="https://calendly.com/dr-ksiddharth/product-demo"
                target="_blank"
                class="tdr-button br"
              >
                Connect with Us
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-7 order-2 order-md-1 py-5">
          <h2>Organizational Wellbeing score</h2>
          <div class="how-w-content">
            <p>
              A wellbeing score for organizations is a holistic assessment that
              evaluates the overall health and happiness of a company's
              workforce. It considers physical, mental, emotional well-being and
              several other indicators of workplace culture to help
              organizations gauge employee satisfaction, engagement, and overall
              health. This score can provide valuable insights for improving
              workplace wellness programs and enhancing the overall work
              environment.
            </p>
            <p>
              This is highly recommended for organizations beginning their
              wellness journey and to advance forward with data and intelligence
              that will help fix KPIs impacting the bottom line, giving
              management a measurable experience and appreciate value on
              investment in wellness
            </p>

            <div class="button-grp mt-4">
              <a
                [href]="saasBaseUrl + '/open/organizational-assessment'"
                target="_blank"
                class="tdr-button br w-100"
              >
                Get your organizational Wellbeing score
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-1 order-md-2 center-content">
          <img class="rounded" src="assets/img/services/wellbe-score.jpeg" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-5 order-1 order-md-1 center-content">
          <img class="rounded" src="assets/img/services/vr-experience.jpeg" />
        </div>
        <div class="col-sm-7 order-2 order-md-2 py-5">
          <h2>VR Relaxation Room</h2>
          <div class="how-w-content">
            <p>
              A virtual reality relaxation room within corporate premises is a
              dedicated space equipped with VR technology designed to offer
              employees a tranquil and immersive escape from the workplace. It
              provides a unique and rejuvenating experience, allowing
              individuals to reduce stress, boost mental well-being, and enhance
              focus and productivity by immersing themselves in virtual
              environments that promote relaxation, mindfulness, and stress
              relief. This innovative wellness feature can contribute to a more
              balanced and motivated workforce.
            </p>

            <div class="button-grp mt-4">
              <a routerLink="/mental-wellness" class="tdr-button br">
                Explore Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-7 order-2 order-md-1 py-5">
          <h2>VR Experience Center</h2>
          <div class="how-w-content">
            <p>
              A virtual reality experience center for mental health at an
              academic institution is a specialized facility that employs VR
              technology to support students' mental well-being. It offers
              immersive experiences designed to reduce stress, manage anxiety,
              and enhance mindfulness. Students can explore virtual environments
              aimed at improving mental health, learning coping strategies, and
              gaining valuable tools for self-care. This resource contributes to
              a more supportive and resilient academic community.
            </p>

            <div class="button-grp mt-4">
              <a routerLink="/mental-wellness" class="tdr-button br">
                Explore Now
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-5 order-1 order-md-2 center-content">
          <img class="rounded" src="assets/img/services/vr-relaxation.jpeg" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-5 order-1 order-md-1 center-content">
          <img
            class="rounded"
            src="assets/img/services/alkaline-drinking-water.jpeg"
          />
        </div>
        <div class="col-sm-7 order-2 order-md-2 py-5">
          <h2>Pure Alkaline drinking water</h2>
          <div class="how-w-content">
            <p>
              Pure alkaline drinking water for corporates is a premium water
              option that is filtered and treated to have a higher pH level,
              making it alkaline. This type of water is believed to provide
              potential health benefits, including better hydration and balance
              in the body's pH levels. Offering pure alkaline water in corporate
              settings promotes employee wellness and provides a refreshing and
              health-conscious beverage option for the workforce.
            </p>

            <div class="button-grp mt-4">
              <a
                href="https://calendly.com/dr-ksiddharth/product-demo"
                target="_blank"
                class="tdr-button br"
              >
                Explore Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section">
  <div class="ex-course">
    <div class="container">
      <app-enquiry-form
        [enquiryAs]="'Distributor'"
        [enquiryFor]="'Distributor'"
      ></app-enquiry-form>
    </div>
  </div>
</section>

<section class="section gradient">
  <app-our-mobile-app></app-our-mobile-app>
</section>
