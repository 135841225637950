import {
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, finalize, map, throwError, timeout } from 'rxjs';
import { AuthService } from '../serivce/auth.service';
import { NotificationService } from './notification.service';

export function AuthorizationInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const _authService = inject(AuthService);
  const _notificationService = inject(NotificationService);

  const accessToken = _authService.accessToken$;
  let headers = request.headers;
  if (request.headers?.get('Content') !== 'file') {
    const contentType = request.headers?.get('Content-Type');
    if (contentType) {
      headers = headers.set('Content-Type', contentType);
    } else {
      headers = headers.set('Content-Type', 'application/json');
    }
  }
  if (
    accessToken &&
    !request.headers?.get('Authorization') &&
    request.headers?.get('APIType') !== 'ExternalAPI'
  ) {
    headers = headers.set('Authorization', `Bearer ${accessToken}`);
  }
  headers.delete('APIType');
  request = request.clone({ headers });
  return next(request).pipe(
    map((event: any) => {
      if (event instanceof HttpResponse) {
        if (event && event.body?.message && !event.body?.status) {
          _notificationService.notify(event.body.message);
          return throwError(() => new Error('Info'));
        }
      }
      return event;
    }),
    catchError((response) => {
      if (
        response.status === HttpStatusCode.BadRequest ||
        response.status === HttpStatusCode.UnprocessableEntity
      ) {
        if (response.error?.message) {
          _notificationService.notify(response.error.message);
        } else if (response.data) {
          response.data?.forEach((error: string) => {
            _notificationService.notify(error);
          });
        }
      } else if (response.status === HttpStatusCode.Unauthorized) {
        _notificationService.notify('Unauthorized');
        _authService.signOut();
      } else if (response.status === HttpStatusCode.Forbidden) {
        _notificationService.notify('Access denied.');
      } else if (
        response.status === HttpStatusCode.InternalServerError ||
        response.status === HttpStatusCode.BadGateway ||
        response.status === HttpStatusCode.ServiceUnavailable ||
        response.status === HttpStatusCode.GatewayTimeout
      ) {
        let refId!: string;
        if (response.error) {
          refId = `[RefID: ${response.error}]`;
        }
        _notificationService.notify(
          `Service is Temporarily unavailable. ${refId}`
        );
      }
      return throwError(() => response);
    }),
    timeout(120000),
    finalize(() => {})
  );
}
