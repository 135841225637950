<div class="container mt-4">
  <div class="row enquiry-form">
    <div class="col-md-12">
      <form class="form-box" [formGroup]="form">
        <h3>Have an enquiry?</h3>
        <div class="text-left">
          <div class="form-group">
            <label>Name</label>
            <mat-form-field class="w-100" appearance="outline">
              <input matInput type="text" formControlName="name" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <label>Email</label>
            <mat-form-field class="w-100" appearance="outline">
              <input matInput type="email" formControlName="email" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <label>Mobile</label>
            <mat-form-field class="w-100" appearance="outline">
              <input matInput type="text" formControlName="mobile" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <label>Country</label>
            <mat-form-field class="w-100" appearance="outline">
              <mat-select formControlName="country" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Country"
                    [formControl]="countryFilterCtrl"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  *ngFor="let each of filteredCountries | async"
                  [value]="each.id"
                >
                  {{ each.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="enquiryAs == null">
            <label>Query As</label>
            <mat-form-field class="w-100" appearance="outline">
              <mat-select formControlName="queriedAs">
                <mat-option value="Corporate">Corporate</mat-option>
                <mat-option value="Employee">Employee / individual</mat-option>
                <mat-option value="Hospital">Hospital</mat-option>
                <mat-option value="Practitioner">Practitioner</mat-option>
                <mat-option value="Distributor">Distributor</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="enquiryFor != 'Distributor'">
            <label>Query For</label>

            <mat-form-field class="w-100" appearance="outline">
              <mat-select formControlName="queriedFor">
                <mat-option value="Mental Wellness">Mental Wellness</mat-option>
                <mat-option value="Ayurveda">Ayurveda</mat-option>
                <mat-option value="Distributor">Distributor</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="enquiryFor == 'Distributor'">
            <label>Query For</label>
            <mat-form-field class="w-100" appearance="outline">
              <mat-select formControlName="queriedFor">
                <mat-option value="Organizational Wellbe Score">
                  Organizational Wellbe Score
                </mat-option>
                <mat-option value="VR Exposure Therapy">
                  VR Exposure Therapy
                </mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label>Query</label>
            <mat-form-field class="w-100" appearance="outline">
              <textarea matInput formControlName="query"> </textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="mt-5">
          <re-captcha
            #enquiryCaptchaRef="reCaptcha"
            (resolved)="enquiryCaptchaResolved($event)"
            size="invisible"
          >
          </re-captcha>

          <button
            class="submit"
            (click)="verifyEnquiryCaptcha(enquiryCaptchaRef)"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
