<ngx-slick-carousel
  class="carousel tdr-team"
  #slickModal="slick-carousel"
  [config]="slideConfig"
>
  <div *ngFor="let slideArr of slides">
    <div class="flex-box py-5">
      <div
        ngxSlickItem
        *ngFor="let slide of slideArr"
        class="slide tdr-team-box"
        [ngClass]="slide.class"
      >
        <div class="tdr-team-inner-box" *ngFor="let item of slide.items">
          <img
            [src]="item.img"
            [alt]="item.name"
            [title]="item.name"
            data-toggle="popover"
            data-html="true"
            [attr.data-content]="item.about"
          />
        </div>
      </div>
    </div>
  </div>
</ngx-slick-carousel>
