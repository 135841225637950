<!--banner start-->
<section class="banner">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 order-2 order-xl-1 mt-4">
        <div class="banner-box">
          <h3 class="be">Wellbe</h3>
          <h1 class="be">Holistic &</h1>
          <h2 class="be">Personalized</h2>
        </div>
      </div>
      <div class="col-xl-7 order-1 order-xl-2 mt-4">
        <div class="banner-service-box">
          <a routerLink="/dosha">Check Your Dosha</a>
          <a routerLink="/diet-plan">Get Your Smart Diet Plan with AI</a>
          <a
            [href]="saasBaseUrl + '/open/organizational-assessment'"
            target="_blank"
          >
            Get Your Wellbe Score
          </a>
          <a>VR Relaxation</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--banner end-->

<section class="section gradient">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of services">
            <ng-template carouselSlide>
              <div class="service-box m-2">
                <img [src]="item.imageSrc" [alt]="item.label" />
                <div class="title-container text-center">
                  <h3>{{ item.label }}</h3>
                </div>
                <a [routerLink]="item.routerLink" class="arrow-link">
                  <img src="assets/img/right-arrow.png" />
                </a>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div class="row alternative">
      <div class="col-md-4 col-12">
        <p class="light">
          Embark on a transformative journey towards optimal health, balance and
          complete wellbeing.
        </p>
      </div>
      <div class="col-md-8 col-12">
        <h3>On demand alternative medicine & lifestyle.</h3>
        <div class="button-grp">
          <a
            [href]="saasBaseUrl + '/sign-in?redirectURL=/webapp/assessment'"
            target="_blank"
            class="tdr-button main"
          >
            Mind Body Type Assessment
          </a>
        </div>
      </div>
    </div>
    <div class="row watch-video">
      <div class="col-md-6 col-12">
        <div class="service-box sec">
          <img src="assets/img/yoga-girl.png" alt="yoga girl" />
          <h3>
            Personalized <br />Approach.
            <small
              >Enjoy a wellness journey combining Ayurveda and alternative
              medicine for an enriched life.
            </small>
            <br />Maximum Impact.
          </h3>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="video-box p-2">
          <img
            src="assets/img/watch-video.png"
            alt="video thumnail"
            *ngIf="!watchVideo"
          />
          <iframe
            style="width: 100%; height: 700px"
            src="https://www.youtube.com/embed/7FbbzgtwgMc"
            title="wellbe demo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            *ngIf="watchVideo"
          ></iframe>
          <button class="btn btn-link" (click)="watchVideo = !watchVideo">
            {{ !watchVideo ? "Watch Our Video" : "Close Video" }}
            <img
              src="assets/img/youtube-logo.png"
              width="50px"
              *ngIf="!watchVideo"
            />
          </button>
        </div>
        <div class="video-content">
          <h4>Check your <br />Wellbe score</h4>
          <p>
            Get a complete picture of your health, likelihood of disease and
            chronic illnesses and nutrition requirements according to your
            unique mind-body type.
          </p>
          <a
            [href]="saasBaseUrl + '/open/organizational-assessment'"
            target="_blank"
            class="tdr-button main"
            >Check your score</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section gradient">
  <!-- <img
    class="img-fluid w-100"
    src="assets/img/Diet-Planner.jpeg"
    routerLink="/diet-plan"
  /> -->
  <app-our-clients></app-our-clients>
  <app-testimonials></app-testimonials>
</section>

<section class="section gradient">
  <app-storyboard></app-storyboard>
  <app-our-mobile-app></app-our-mobile-app>
</section>
