import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storyboard',
  templateUrl: './storyboard.component.html',
  styleUrls: ['./storyboard.component.scss'],
})
export class StoryboardComponent {
  saasBaseUrl = environment.saasBaseUrl;
  basePath: string = '';
  storyboardFile: string = '';
  constructor() {
    if (environment) {
      this.basePath = `${window.location.protocol}//${window.location.host}`;
      if (window.location.host.includes('localhost')) {
        this.basePath = 'https://wellbe.fit';
        this.basePath = 'https://wellbe.knownicely.com';
      }
      this.storyboardFile = `https://view.officeapps.live.com/op/embed.aspx?src=${this.basePath}/assets/document/Wellbe%20storyboard.pptx`;
    }
  }
}
