<form [formGroup]="form" class="mt-2">
  <ng-template matStepLabel>Know your Dosha</ng-template>
  <mat-vertical-stepper
    id="mindBodyType"
    [linear]="isLinear"
    #hstepper="matStepper"
  >
    <mat-step [stepControl]="getControl('frame')">
      <ng-template matStepLabel>Frame</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="frame">
        <mat-radio-button value="Vata">
          I'm thin, lanky, slender with prominent joints and thin muscle
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          I have a medium symmetrical build with good muscle development
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          I have a large, round or stocky build. My frame is broad, stout or
          thick
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('weight')">
      <ng-template matStepLabel>Weight</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="weight">
        <mat-radio-button value="Vata">
          Low, I may forget to eat or have a tendency to lose weight
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          Moderate, it is easy for me to gain or lose weight if I put my mind to
          it
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          Heavy, I gain weight easily and have difficulty losing it
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('eyes')">
      <ng-template matStepLabel>Eyes</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="eyes">
        <mat-radio-button value="Vata">
          My eyes are small and active
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          I have a penetrating gaze
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          I have a large pleasant eye
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('complexion')">
      <ng-template matStepLabel>Complexion</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="complexion">
        <mat-radio-button value="Vata">
          My skin is dry, rough or thin
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          My skin is warm, reddish in color and prone to irritation
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          My skin is thick moist and smooth
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('hair')">
      <ng-template matStepLabel>Hair</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="hair">
        <mat-radio-button value="Vata">
          My hair is dry, brittle or frizzy
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          My hair is fine with a tendency towards early thinning or graying
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          I have abundant, thick and oily hair
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('joints')">
      <ng-template matStepLabel>Joints</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="joints">
        <mat-radio-button value="Vata">
          My joints are thin and prominent and have a tendency to crack
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          My joints are loose and flexible
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          My joints are large well-knit and padded
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('sleepPattern')">
      <ng-template matStepLabel>Sleep Pattern</ng-template>
      <mat-radio-group
        class="d-flex flex-column"
        formControlName="sleepPattern"
      >
        <mat-radio-button value="Vata">
          I'm a light sleeper with a tendency to awaken early
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          I'm a moderately sound sleeper usually needing less than 8 hours to
          feel rested
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          My sleep is deep and long, I tend to awaken slowly in the morning
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('bodyTemperature')">
      <ng-template matStepLabel>Body Temperature</ng-template>
      <mat-radio-group
        class="d-flex flex-column"
        formControlName="bodyTemperature"
      >
        <mat-radio-button value="Vata">
          My hands and feet are usually cold and I prefer a warm environment
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          I'm usually warm regardless of the season and prefer cooler
          environments
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          I'm adaptable to most temperatures but do not like cold wet days
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('temprament')">
      <ng-template matStepLabel>Temprament</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="temprament">
        <mat-radio-button value="Vata">
          I'm lively and enthusiastic in nature. I like to change
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          I'm purposeful and intense. I like to convince
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          I'm easy going and accepting. I like to support
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
        <button mat-stroked-button color="accent" class="mr-3" matStepperNext>
          Next
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="getControl('underStress')">
      <ng-template matStepLabel>Under Stress</ng-template>
      <mat-radio-group class="d-flex flex-column" formControlName="underStress">
        <mat-radio-button value="Vata">
          I become anxious and /or worried
        </mat-radio-button>
        <mat-radio-button value="Pitta">
          I become irritable and/or aggressive
        </mat-radio-button>
        <mat-radio-button value="Kapha">
          I become withdrawn and/or reclusive
        </mat-radio-button>
      </mat-radio-group>
      <div class="mt-3">
        <button
          mat-stroked-button
          color="accent"
          class="mr-3"
          matStepperPrevious
        >
          Back
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <div class="m-5">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      (click)="submit()"
    >
      Get Result
    </button>
  </div>
</form>
