<div class="gradient">
  <div class="container">
    <div
      id="carouselExampleControls"
      class="carousel slide indi-slider"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="d-block w-100"
            src="assets/img/Group 222.png"
            alt="First slide"
          />
          <!-- <div class="slider-text"><h1>Your health in your hands</h1></div> -->
        </div>
        <!-- <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/img/Group 222.png"
            alt="Second slide"
          />
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="assets/img/Group 222.png"
            alt="Third slide"
          />
        </div> -->
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="" aria-hidden="true"
          ><i class="fa-solid fa-arrow-left"></i
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="" aria-hidden="true"
          ><i class="fa-solid fa-arrow-right"></i
        ></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>

<div class="contact-with mt-5">
  <div class="container">
    <div class="contact-head">
      <h2>
        Thank you for your interest in becoming a valued distributor of WellBe.
        At WellBe, we take pride in delivering high-quality products to
        customers around the world, and we recognize the crucial role that
        distributors play in our global success.
      </h2>
    </div>
  </div>
</div>

<div class="gradient">
  <div class="container">
    <div class="my-5 how-content-1">
      <div class="row">
        <div class="col-sm-5 order-2 order-md-2 py-5">
          <h2>Why Partner with Us?</h2>
          <div class="how-w-content">
            <p>
              <b>Quality Products</b> We offer a wide range of top-tier products
              that have been meticulously crafted and tested to meet the highest
              standards. Our commitment to quality is unwavering, and we're
              dedicated to providing distributors with products that they can
              confidently stand behind.
            </p>

            <p>
              <b>Diverse Product Line</b> Our product portfolio spans across
              various industries and sectors, catering to a broad spectrum of
              customer needs. Whether it's
              <b>
                Virtual Reality Exposure Therapy, WellBe score, Voice based
                Sentiment Analysis and Pure Alkaline Water
              </b>
              , we have the perfect products to meet market demand.
            </p>

            <p>
              <b>Strong Brand Recognition</b>WellBe has built a reputation for
              excellence, reliability, and innovation. By partnering with us,
              you align your business with a brand that is trusted and respected
              in the industry.
            </p>

            <p>
              <b>Support and Training</b> We believe in the success of our
              distributors. That's why we offer comprehensive training,
              marketing materials, and ongoing support to help you effectively
              promote and sell our products.
            </p>
          </div>
        </div>

        <div class="col-sm-7 order-1 order-md-1">
          <img src="assets/img/TechEnabledPartnership-1.png" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-7 order-1 order-md-2">
          <img src="assets/img/BespokeSolutions-1.png" />
        </div>
        <div class="col-sm-5 order-2 order-md-1 py-5">
          <h2>The Benefits of Being a WellBe Distributor</h2>
          <div class="how-w-content">
            <p>
              <b>Profitable Opportunities</b> As a distributor, you have the
              potential to enjoy substantial profits and healthy margins. Our
              competitive pricing and incentives are designed to support your
              success.
            </p>

            <p>
              <b>Exclusive Territories</b> We assign exclusive territories to
              our distributors, ensuring that you have the autonomy to build
              your business and relationships in your region.
            </p>

            <p>
              <b>Marketing and Sales Support</b>Our marketing and sales support
              services provide you with the tools, knowledge, and resources
              needed to effectively market and sell our products.
            </p>

            <p>
              <b>Product Customization</b> Depending on your market and customer
              needs, we can explore product customization options to cater to
              unique demands.
            </p>

            <p>
              <b>Long-Term Partnership</b> We're not just looking for
              distributors; we're looking for partners. We believe in nurturing
              long-term relationships, and we'll work closely with you to ensure
              your success.
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-12">
          <h2>Join Us Today</h2>
          <div class="how-w-content">
            <p>
              If you're interested in becoming a distributor for WellBe, we
              invite you to take the next step in joining our network of
              successful partners. Together, we can achieve great things and
              provide our customers with the exceptional products and service
              they deserve.
            </p>

            <p>
              To learn more about our distributor opportunities and how you can
              get started, please <b>contact us or apply now</b>.
            </p>

            <p>
              WellBe looks forward to the opportunity to work with you and
              expand our reach and impact together. Thank you for considering us
              as your distribution partner.
            </p>

            <p>
              <b>Join us</b> in shaping a brighter future with quality products
              and a reliable partnership.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section">
  <div class="ex-course">
    <div class="container">
      <app-enquiry-form
        [enquiryAs]="'Distributor'"
        [enquiryFor]="'Distributor'"
      ></app-enquiry-form>
    </div>
  </div>
</section>

<section class="section gradient">
  <app-our-mobile-app></app-our-mobile-app>
</section>
