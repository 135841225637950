<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="footer-section-1 text-center">
          <div class="footer-logo">
            <img src="assets/img/Wellbe-Logo_Artboard-4.png" />
          </div>
          <button class="play-btn">
            <img src="assets/img/play.png" />Download for Android
          </button>
          <button class="play-btn">
            <img src="assets/img/apply.png" />Download for ios
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <div class="footer-section-2">
          <ul>
            <li>
              <a routerLink="/corporate"> Enterprise </a>
            </li>
            <li>
              <a routerLink="/individual"> Individuals</a>
            </li>
            <li>
              <a routerLink="/practitioners">Practitioners</a>
            </li>
            <li>
              <a routerLink="/about-us">About Us</a>
            </li>
            <li>
              <a routerLink="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4">
        <div class="footer-section-2 footer-section-3">
          <p>Redefining Wellbeing Pvt. Ltd.</p>
          <div class="add-footer">
            <p class="addrss">
              Address: E-43/1, Okhla Phase II, Okhla Industrial Area, New Delhi
              Delhi - 110020, India
            </p>
            <p>
              m: +91-90350 33712<br />
              +91-98400 42342
            </p>
            <p>
              Email: <br />
              redwellbe@gmail.com dr.ksiddharth@wellbe.fit
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
