<section class="section gradient" id="section-vr-headset">
  <div class="container">
    <h1 class="text-center">VR Headset</h1>

    <div class="mt-5">
      <div class="text-center" style="position: relative">
        <!-- <img
        class="meta-vr-img border-radius-50"
        src="assets/img/meta-quest/img-3.png"
      /> -->

        <ul
          class="nav nav-pills my-4 justify-content-center"
          id="vr-pills-tab"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="vr-pills-meta-quest-tab"
              data-toggle="pill"
              href="#vr-pills-meta-quest"
              role="tab"
              aria-controls="vr-pills-meta-quest"
              aria-selected="true"
            >
              <div class="text-center h3 py-3 px-5">
                <img src="assets/img/meta-quest/img-5.png" />
                <p class="mt-3">Meta Quest 2</p>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="vr-pills-pico-tab"
              data-toggle="pill"
              href="#vr-pills-pico"
              role="tab"
              aria-controls="vr-pills-pico"
              aria-selected="false"
            >
              <div class="text-center h3 py-3 px-5">
                <img src="assets/img/pico/img-5.png" />
                <p class="mt-3">Pico 4</p>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="vr-pills-focus-tab"
              data-toggle="pill"
              href="#vr-pills-focus"
              role="tab"
              aria-controls="vr-pills-focus"
              aria-selected="false"
            >
              <div class="text-center h3 py-3 px-5">
                <img src="assets/img/focus/img-4.png" />
                <p class="mt-3">HTC Focus 3</p>
              </div>
            </a>
          </li>
        </ul>
        <div class="tab-content" id="vr-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="vr-pills-meta-quest"
            role="tabpanel"
            aria-labelledby="vr-pills-meta-quest-tab"
          >
            <div class="row meta-quest-section my-5">
              <div class="col-lg-7">
                <img
                  class="border-radius-50 mt-5"
                  height="350"
                  src="assets/img/meta-quest/img-3.png"
                />
                <div class="features mt-5">
                  <h1>Meta Quest 2</h1>
                  <div class="text-left text-justify px-5">
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>HARDWARE </b>:
                      Enjoy advanced all-in-one VR with just a headset and
                      controllers.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>GRAPHICS </b>:
                      Enjoy increased pixel density from dynamic resolution
                      scaling, supporting smoother game play and seamless
                      immersion.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>TRACKING</b> :
                      With 6DOF, the headset tracks the movement of both your
                      head and body, then translates them into VR with realistic
                      precision. No external sensors required.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i>
                      <b>CONTROLLERS</b> : Meta Quest 2 Touch controllers have
                      been upgraded with improved ergonomics. A new thumb rest
                      adds stability when needed.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i>
                      <b>HEAD STRAP </b>: Designed to offer lightweight comfort
                      for any type of player. This soft strap can be easily
                      adjusted or upgraded with Meta Quest accessories.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b> SOUND </b>: 3D
                      positional audio is built directly into the headset,
                      allowing you to hear what's all around you. The 3.5 mm
                      audio port lets you play with or without headphones.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <img
                  class="border-radius-50 my-5"
                  height="600"
                  src="assets/img/meta-quest/img-4.png"
                />
                <div class="mt-5">
                  <p class="h2 color-navy-blue">Interested in Buying?</p>
                  <app-connect-with [connectWith]="'sales'"></app-connect-with>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="vr-pills-pico"
            role="tabpanel"
            aria-labelledby="vr-pills-pico-tab"
          >
            <div class="row meta-quest-section my-5">
              <div class="col-lg-7">
                <img
                  class="border-radius-50 mt-5"
                  height="350"
                  src="assets/img/pico/img-3.png"
                />
                <div class="features mt-5">
                  <h1>Pico 4</h1>
                  <div class="text-left text-justify px-5">
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>HARDWARE </b>:
                      Enjoy advanced all-in-one VR with just a headset and
                      controllers. Powered with Qualcomm XR2, Kryo 585, 8 cores,
                      64-bit, 2.84 GHz, 7nm
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>GRAPHICS </b>:
                      Enjoy increased pixel density from dynamic resolution
                      scaling, supporting smoother game play and seamless
                      immersion.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>Screen </b>:
                      Resolution: 4320 × 2160 (2160 × 2160 per eye), 1200 PPI 72
                      Hz/90 Hz refresh rate
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>TRACKING</b> :
                      Inside-out 6DoF tracking, HD seethrough and multi-room
                      guardian system. 26DoF hand tracking with a supports of 4
                      gestures
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i>
                      <b>CONTROLLERS</b> : 6DoF tracking with 20 infrared
                      sensors. Buttons: Joystick, Trigger, Menu, (Screen)
                      Capture, Home, X/Y (left controller), A/B (right
                      controller)
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b> SOUND </b>: 3D
                      Stereo speakers for 360° sound with 3D spatial effects.
                      Lowest frequency: 200Hz.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <img
                  class="border-radius-50 my-5"
                  height="600"
                  src="assets/img/pico/img-1.webp"
                />
                <div class="mt-5">
                  <p class="h2 color-navy-blue">Interested in Buying?</p>
                  <app-connect-with [connectWith]="'sales'"></app-connect-with>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="vr-pills-focus"
            role="tabpanel"
            aria-labelledby="vr-pills-focus-tab"
          >
            <div class="row meta-quest-section my-5">
              <div class="col-lg-7">
                <img
                  class="border-radius-50 mt-5"
                  height="350"
                  src="assets/img/focus/img-2.png"
                />
                <div class="features mt-5">
                  <h1>HTC Focus 3</h1>
                  <div class="text-left text-justify px-5">
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>HARDWARE </b>:
                      Enjoy advanced all-in-one VR with just a headset and
                      controllers. Powered with Qualcomm® Snapdragon™ XR2
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>GRAPHICS </b>:
                      Enjoy increased pixel density from dynamic resolution
                      scaling, supporting smoother game play and seamless
                      immersion.
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>Screen </b>:
                      Dual 2.88" LCD panels, 2448 x 2448 pixels per eye (4896 x
                      2448 pixels combined) 90 Hz refresh rate
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b>TRACKING</b> :
                      VIVE Inside-out Tracking. Up to 10x10m play space
                      recommended
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i>
                      <b>CONTROLLERS</b> : Hall sensors on Trigger and Grip
                      buttons. Capacitive sensors on Trigger, Joystick, and
                      Thumb-rest area with G-Sensor and Gyroscope
                    </p>
                    <p>
                      <i class="fa fa-check-circle mr-2"></i> <b> SOUND </b>: 3D
                      Dual microphones with echo cancellation. 2x Dual driver
                      with patented directional speaker design with Hi-Res
                      Certified 3.5mm audio jack output
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <img
                  class="border-radius-50 my-5"
                  height="600"
                  src="assets/img/focus/img-1.png"
                />
                <div class="mt-5">
                  <p class="h2 color-navy-blue">Interested in Buying?</p>
                  <app-connect-with [connectWith]="'sales'"></app-connect-with>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="meta-quest-section my-5">
          <div class="img-sec" style="margin-bottom: 200px">
            <img
              class="border-radius-50 mt-5"
              src="assets/img/meta-quest/img-1.jpeg"
            />
          </div>
          <div class="img-sec" style="margin-top: 200px">
            <img
              class="border-radius-50 mt-5"
              src="assets/img/meta-quest/img-2.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
