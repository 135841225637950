import { Component } from '@angular/core';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss'],
})
export class CorporateComponent {
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
