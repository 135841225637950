<div class="gradient">
  <div class="container">
    <div class="p-md-5 m-md-5">
      <div class="text-center mb-5">
        <h1 class="color-navy-blue font-weight-bold">Know Your Dosha</h1>
      </div>

      <div
        class="card overflow-hidden p-5"
        style="border-radius: 50px; background: #ffffffbf"
      >
        <div *ngIf="part == 1">
          <dosha-assessment
            *ngIf="!isSubmitted"
            (submitted)="isSubmitted = $event; part = 2; scrollToTop()"
            (score)="score = $event"
          ></dosha-assessment>
        </div>
        <div *ngIf="part == 2">
          <div class="row enquiry-form">
            <div class="col-md-12">
              <label class="font-weight-bold mx-2">
                Please share Email & Mobile to provide your Dosha insights &
                Report
              </label>
              <form class="text-center" [formGroup]="form">
                <div class="d-block">
                  <mat-form-field appearance="outline" class="mt-3 mx-2 w-100">
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      placeholder="Email"
                      formControlName="email"
                    />
                    <mat-hint>Please enter your email address</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="mt-3 mx-2 w-100">
                    <mat-label>Mobile</mat-label>
                    <input
                      matInput
                      placeholder="Mobile"
                      formControlName="mobile"
                    />
                    <mat-hint>Please enter your mobile number</mat-hint>
                  </mat-form-field>
                </div>

                <re-captcha
                  #doshaCaptchaRef="reCaptcha"
                  (resolved)="doshaCaptchaResolved($event)"
                  size="invisible"
                >
                </re-captcha>

                <button
                  mat-raised-button
                  color="primary"
                  class="mt-3 mx-2"
                  (click)="verifyDoshaCaptcha(doshaCaptchaRef)"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div *ngIf="part == 3">
          <div class="row">
            <div class="col-md-6">
              <div
                class="text-center d-flex align-items-center justify-content-center h-100"
              >
                <div>
                  <p>Your Mind-Body Type is:</p>
                  <h1 class="font-weight-bold">{{ score.mindBodyType }}</h1>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3 p-md-5">
              <div class="card p-md-5" style="border-radius: 50px">
                <div class="text-center p-3">
                  <p class="font-weight-bold">Vata</p>
                  <p>This type is full of energy and creativity.</p>

                  <mat-progress-bar
                    [value]="(score.vata * 100) / score.total"
                  ></mat-progress-bar>

                  {{ score.vata / score.total | percent }}
                </div>
                <hr />

                <div class="text-center p-3">
                  <p class="font-weight-bold">Pitta</p>
                  <p>This type are sharp thinkers.</p>

                  <mat-progress-bar
                    [value]="(score.pitta * 100) / score.total"
                  ></mat-progress-bar>

                  {{ score.pitta / score.total | percent }}
                </div>
                <hr />

                <div class="text-center p-3">
                  <p class="font-weight-bold">Kapha</p>
                  <p>This type is warm of heart and steady of mind.</p>

                  <mat-progress-bar
                    [value]="(score.kapha * 100) / score.total"
                  ></mat-progress-bar>

                  {{ score.kapha / score.total | percent }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
