import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiResponse } from '../models/api-res.model';
import {
  ICorporate,
  IUserLoginData,
  UserResModel,
} from '../models/user-loign.model';
import { IUserRegisterResData } from '../models/user-register.model';
import { EncryptionService } from '../shared/encryption.service';
import { StorageService } from '../shared/storage.service';

export interface IJwtTokenData {
  iss: string; // Issuer
  aud: string; // Audience
  exp: number; // Expiry
  nameid: string; // UserId
  sid: string; // SessionId
  tid: string; // TenantId
  primarygroupsid: string; // CorporateId
  groupsid: string; // UserGroupId
  role: string; //Role
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _tokenData = new ReplaySubject<IJwtTokenData>(1);
  private _user = new ReplaySubject<UserResModel>(1);
  private _loginData = new ReplaySubject<IUserLoginData>(1);

  set accessToken(token: string) {
    this._storageService.setValueToLocalStorage(
      environment.storage.loginTokenKey,
      token
    );
  }

  get accessToken$(): string {
    return (
      this._storageService.getValueFromLocalStorage(
        environment.storage.loginTokenKey
      ) ?? ''
    );
  }

  deleteAccessToken$(): void {
    this._storageService.removeLocalStorageKey(
      environment.storage.loginTokenKey
    );
  }

  set user(value: UserResModel) {
    this._user.next(value);
  }

  get user$(): Observable<UserResModel> {
    return this._user.asObservable();
  }

  set loginData(value: IUserLoginData) {
    this._loginData.next(value);
  }

  get loginData$(): Observable<IUserLoginData> {
    return this._loginData.asObservable();
  }

  set tokenData(tokenData: IJwtTokenData) {
    this._tokenData.next(tokenData);
  }

  get tokenData$(): Observable<IJwtTokenData> {
    return this._tokenData.asObservable();
  }

  constructor(
    private _httpClient: HttpClient,
    private _storageService: StorageService,
    private _encryptionService: EncryptionService,
    private _router: Router
  ) {}

  register(data: any): Observable<IApiResponse<IUserRegisterResData>> {
    return this._httpClient
      .post<IApiResponse<IUserRegisterResData>>(
        `${environment.apiUrl}/g-auth/v1.0/auth/register`,
        data
      )
      .pipe(
        switchMap((apiRes) => {
          if (apiRes.status) {
            const data = apiRes.data;
            this.clearAllStorage();
            this._storageService.setValueToLocalStorage(
              environment.storage.loginDataKey,
              JSON.stringify(data)
            );
            this.accessToken = data.loginData.accessToken;
            this.tokenData = this._encryptionService.decodeJwtToken(
              data.loginData.accessToken
            );
            this.user = data.loginData.user;
          }
          return of(apiRes);
        })
      );
  }

  getUserAccounts(): Observable<IApiResponse<ICorporate[]>> {
    return this._httpClient.get<IApiResponse<ICorporate[]>>(
      `${environment.apiUrl}/g-auth/v1.0/auth/accounts`
    );
  }

  signOut(): void {
    this.clearAllStorage();
    this._router.navigateByUrl('/sign-in');
  }

  clearAllStorage(): void {
    this._storageService.clearLocalStorage();
    this._storageService.clearSessionStorage();
    this._storageService.clearCookies();
  }

  generateUserGroupToken(id: string): Observable<boolean> {
    return this._httpClient
      .get<IApiResponse<IUserLoginData>>(
        `${environment.apiUrl}/g-auth/v1.0/auth/generate-user-group-token/${id}`
      )
      .pipe(
        switchMap((apiRes) => {
          if (apiRes.status) {
            const data = apiRes.data;
            this.clearAllStorage();
            this._storageService.setValueToLocalStorage(
              environment.storage.loginDataKey,
              JSON.stringify(data)
            );
            this.accessToken = data.accessToken;
            this.tokenData = this._encryptionService.decodeJwtToken(
              data.accessToken
            );
            this.user = data.user;

            // Return true
            return of(true);
          }

          // Return false
          return of(false);
        })
      );
  }

  isRegisteredUser(data: any): Observable<IApiResponse<boolean>> {
    return this._httpClient.post<IApiResponse<boolean>>(
      `${environment.apiUrl}/g-auth/v1.0/auth/is-registered-user`,
      data
    );
  }
}
