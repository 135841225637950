import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public _message$ = new Subject<string>();

  constructor() {}

  notify(message: string): void {
    this._message$.next(message);
  }
}
