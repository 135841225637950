import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-mental-wellness',
  templateUrl: './mental-wellness.component.html',
  styleUrls: ['./mental-wellness.component.scss'],
})
export class MentalWellnessComponent {
  bannerItems: {
    desc: string;
    img: string;
    class?: string;
    headingColor?: string;
    quoteColor?: string;
  }[] = [
    {
      img: 'assets/img/mental-wellness/banner-1.jpg',
      desc: 'Utilize VR therapy to heal patients by guiding them to conquer negative emotions and explore uncharted possibilities in life',
      class: 'flip',
      headingColor: '#f97198',
      quoteColor: '#004251',
    },
    {
      img: 'assets/img/mental-wellness/banner-4.avif',
      desc: 'In the realm of virtual reality, we find real solutions for healing minds and mending hearts',
      class: 'flip',
      headingColor: '#fdad0f',
      quoteColor: '#004251',
    },
    {
      img: 'assets/img/mental-wellness/banner-3.avif',
      desc: 'Through the power of virtual reality, we transform fear into courage, sadness into hope, and pain into strength',
      headingColor: '',
      quoteColor: '',
    },
    // {
    //   img: 'assets/img/mental-wellness/banner-2.avif',
    //   desc: "Step into virtual reality, step out with a renewed spirit – it's not just healing, it's rebirth",
    //   class: 'flip',
    //   headingColor: '#e97c52',
    //   quoteColor: '#004251',
    // },
  ];

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    nav: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    margin: 100,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
  };

  scrollToSection(fragment: string) {
    document.getElementById(fragment)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
