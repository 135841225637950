import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import jwtDecode from 'jwt-decode';
import { IJwtTokenData } from '../serivce/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private secretKey = CryptoJS.enc.Utf8.parse(
    'ReddkQy7HstA1PF4PuPanex6sTwjVzQW'
  );
  private iv = CryptoJS.enc.Utf8.parse('Rwyc57TQ5Kee72b');
  private options = {
    keySize: 128 / 8,
    iv: this.iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };

  constructor() {}

  encryptData(plainData: any): string {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plainData),
      this.secretKey,
      this.options
    ).toString();
  }

  decryptData(cipherData: any): string {
    return CryptoJS.AES.decrypt(
      cipherData,
      this.secretKey,
      this.options
    ).toString(CryptoJS.enc.Utf8);
  }

  randomString(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  decodeJwtToken(token: string): IJwtTokenData {
    if (!token) return {} as IJwtTokenData;
    return jwtDecode(token);
  }
}
