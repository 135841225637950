import { Component } from '@angular/core';

@Component({
  selector: 'app-who-is-this-for',
  templateUrl: './who-is-this-for.component.html',
  styleUrls: ['./who-is-this-for.component.scss']
})
export class WhoIsThisForComponent {

}
