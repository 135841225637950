<section class="section gradient">
  <div class="container">
    <h1 class="text-center">Who Is This For</h1>

    <ul
      class="nav nav-pills my-4 justify-content-center"
      id="pills-tab"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link active"
          id="pills-hospital-tab"
          data-toggle="pill"
          href="#pills-hospital"
          role="tab"
          aria-controls="pills-hospital"
          aria-selected="true"
        >
          <div class="text-center h3 p-3">
            <i class="fa-solid fa-hospital mr-1"></i>
            <p class="mt-3">Hospital</p>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-doctor-tab"
          data-toggle="pill"
          href="#pills-doctor"
          role="tab"
          aria-controls="pills-doctor"
          aria-selected="false"
        >
          <div class="text-center h3 p-3">
            <i class="fa-solid fa-user-md mr-1"></i>
            <p class="mt-3">Mental Health Specialist/ Clinic / Doctor</p>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-university-tab"
          data-toggle="pill"
          href="#pills-university"
          role="tab"
          aria-controls="pills-university"
          aria-selected="false"
        >
          <div class="text-center h3 p-3">
            <i class="fa-solid fa-university mr-1"></i>
            <p class="mt-3">University & School</p>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-corporate-tab"
          data-toggle="pill"
          href="#pills-corporate"
          role="tab"
          aria-controls="pills-corporate"
          aria-selected="false"
        >
          <div class="text-center h3 p-3">
            <i class="fa-solid fa-building mr-1"></i>
            <p class="mt-3">Enterprise & HR</p>
          </div>
        </a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-hospital"
        role="tabpanel"
        aria-labelledby="pills-hospital-tab"
      >
        <div class="row">
          <div class="col-lg-6">
            <img
              class="border-radius-50 h-100"
              src="assets/img/empty-hallway-background.avif"
            />
          </div>
          <div class="col-lg-6">
            <div class="my-4">
              <h3>Engaging and Immersive solution for your patients</h3>
              <p>
                Overcome anxieties, anger and PTSD, and practice relaxation
                techniques with Meta Quest
              </p>
            </div>
            <div class="my-4">
              <h3>Effective therapy solution</h3>
              <p>
                Wellbe VR therapy is based on 100+ studies, and is proven by
                professional association
              </p>
            </div>

            <div class="row my-4">
              <div class="col-12 mb-3">
                <h3>Key Features</h3>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Proven Technology
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Innovative & Easy to use
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Effective healing through realistic simulation
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Improved Treatment Outcomes
                </div>
              </div>
            </div>

            <div class="mb-3">
              <h3>Included Services</h3>
            </div>
            <div class="row offers">
              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> FREE ONLINE TRAINING
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> 24/7 TECHNICAL SUPPORT
                </p>
              </div>

              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> MINIMUM ONE (1) YEAR
                  OF SUBSCRIPTION
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-doctor"
        role="tabpanel"
        aria-labelledby="pills-doctor-tab"
      >
        <div class="row">
          <div class="col-lg-6">
            <img
              class="border-radius-50 h-100"
              src="assets/img/physician-medical.avif"
            />
          </div>
          <div class="col-lg-6">
            <div class="my-4">
              <h3>Be part of this transformational journey</h3>
              <p>
                It's time for change, let's reimagine our approach to delivering
                therapy.
              </p>
            </div>

            <div class="row my-4">
              <div class="col-12 mb-3">
                <h3>Key Features</h3>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Proven Technology
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Innovative & Easy to use
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Effective healing through realistic simulation
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Improved Treatment Outcomes
                </div>
              </div>
            </div>

            <div class="mb-3">
              <h3>Included Services</h3>
            </div>
            <div class="row offers">
              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> FREE ONLINE TRAINING
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> 24/7 TECHNICAL SUPPORT
                </p>
              </div>

              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> MINIMUM ONE (1) YEAR
                  OF SUBSCRIPTION
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-university"
        role="tabpanel"
        aria-labelledby="pills-university-tab"
      >
        <div class="row">
          <div class="col-lg-6">
            <img
              class="border-radius-50 h-100"
              src="assets/img/university.jpeg"
            />
          </div>
          <div class="col-lg-6">
            <div class="my-4">
              <h3>New VR chapter of your institution</h3>
              <p>We are making our technology accessible to any institutions</p>
            </div>

            <div class="row my-4">
              <div class="col-12 mb-3">
                <h3>Why Have A Dedicated VR Center</h3>
              </div>

              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Mental healing & Treatment
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  PsychoEducation
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Care of learners' Mental Health
                </div>
              </div>
              <!-- <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Research- based work
                </div>
              </div> -->
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Access to unlimited contents
                </div>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-md-12">
                <div class="card h-100 p-4">
                  <h3>Problems Addressed</h3>
                  <div class="problems mt-3">
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Grief & Loss
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Transition
                      change & Uncertainty
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Homesickness
                      & Social isolation
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Family
                      concerns, Struggle with relationships
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Insomnia,
                      Obsessive & Compulsive behaviour
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Suicidal
                      tendencies, Depression, Stress, Anxiety academic pressure
                      and more factors
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <h3>Key Benefits</h3>
            </div>
            <div class="row offers">
              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> CONTROLLED EXPOSURE
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> PERSONALIZED TREATMENT
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> REALISTIC SIMULATIONS
                </p>
              </div>

              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> GRADUAL PROGRESSION
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> IMPROVED TREATMENT
                  OUTCOMES
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-corporate"
        role="tabpanel"
        aria-labelledby="pills-corporate-tab"
      >
        <div class="row">
          <div class="col-lg-6">
            <img
              class="border-radius-50 h-100"
              src="assets/img/mental-wellness/company.avif"
            />
          </div>
          <div class="col-lg-6">
            <div class="my-4">
              <h3>Your Employee Mental Wellness with VR Exposure Therapy</h3>
              <p>
                Discover our Virtual Reality training programs for overcoming
                anxieties, stress, anger and practising relaxation in the
                workplace
              </p>
            </div>

            <div class="row my-4">
              <div class="col-12 mb-3">
                <h3>Why Have A Dedicated VR Center</h3>
              </div>

              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Mental Counselling
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Relaxation
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Mental healing & Treatment
                </div>
              </div>
              <!-- <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Motivational Session
                </div>
              </div> -->
              <div class="col-md-6 mb-3">
                <div
                  class="card solutions p-4 text-center font-weight-bold h-100 d-flex align-items-center justify-content-center"
                >
                  Access to unlimited contents
                </div>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-md-12">
                <div class="card h-100 p-4">
                  <h3>Problems Addressed</h3>
                  <div class="problems mt-3">
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i>Stress and
                      Burnout
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Anxiety,
                      Depression & Social Isolation
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Bullying and
                      Harassment
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Mood
                      Disorders
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i>
                      Perfectionism
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Work-Life
                      Imbalance
                    </p>
                    <p>
                      <i class="fa fa-angle-double-right mr-2"></i> Substance
                      Abuse
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <h3>Key Benefits</h3>
            </div>
            <div class="row offers">
              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> CONTROLLED EXPOSURE
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> PERSONALIZED TREATMENT
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> REALISTIC SIMULATIONS
                </p>
              </div>

              <div class="col-md-6">
                <p>
                  <i class="fa fa-check-circle mr-2"></i> GRADUAL PROGRESSION
                </p>
                <p>
                  <i class="fa fa-check-circle mr-2"></i> IMPROVED TREATMENT
                  OUTCOMES
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <app-connect-with></app-connect-with>
    </div>
  </div>
</section>
