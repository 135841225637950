<div class="container mt-4">
  <div class="row enquiry-form">
    <div class="col-md-12">
      <form class="form-box text-center">
        <h3>Subscribe for any news and update from Wellbe.</h3>
        <div class="flex">
          <div class="form-group w-100 pr-5">
            <label>Email</label>
            <input class="form-control" type="email" />
          </div>
          <input class="submit" type="submit" value="submit" />
        </div>
      </form>
    </div>
  </div>
</div>
