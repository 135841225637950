<section class="section gradient" id="section-wellness">
  <div class="container">
    <section class="inner-banner">
      <div class="row">
        <div class="col-lg-5 my-3">
          <h1>Wellness</h1>
          <p>
            Virtual Reality (VR) immersive therapy is increasingly being
            recognized as a valuable tool for promoting wellness, relaxation,
            and mindful meditation. By leveraging the immersive and interactive
            capabilities of VR technology, individuals can be transported to
            serene environments, engage in guided meditation, and experience a
            heightened sense of presence.
          </p>
          <p>
            Here's how VR immersive therapy can contribute to wellness,
            relaxation, and mindful meditation:
          </p>

          <div class="row features">
            <div class="col-md-6">
              <p><i class="fa fa-check-circle mr-2"></i> Guided Meditation</p>
              <p><i class="fa fa-check-circle mr-2"></i> Enhanced Immersion</p>
              <p><i class="fa fa-check-circle mr-2"></i> Engaging the Senses</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Access to Diverse
                Settings
              </p>
              <p><i class="fa fa-check-circle mr-2"></i> Measurable Progress</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Overcoming Distractions
              </p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Creating Serene
                Environments
              </p>
            </div>
            <div class="col-md-6">
              <p><i class="fa fa-check-circle mr-2"></i> Accessibility</p>
              <p><i class="fa fa-check-circle mr-2"></i> Stress Reduction</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Novelty and Engagement
              </p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Individual Variability
              </p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Balancing with Reality
              </p>
              <p><i class="fa fa-check-circle mr-2"></i> Qualified Guidance</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Ethical Considerations
              </p>
            </div>
          </div>

          <div class="button-grp mt-4">
            <a [href]="saasBaseUrl" target="_blank" class="tdr-button br">
              Get Started
            </a>
          </div>
        </div>
        <div
          class="col-lg-7 my-3 d-flex justify-content-center align-items-center"
        >
          <img
            class="pill-image w-100"
            style="height: 450px"
            src="assets/img/wellness/wellness.jpeg"
            alt="banner-image"
          />
        </div>
      </div>
    </section>

    <div class="row" style="margin-top: 70px">
      <div
        class="col-md-4 col-sm-6 my-3"
        *ngFor="let service of modules; let i = index"
      >
        <div class="card p-3 services h-100">
          <div class="ptsd text-center">
            <div class="rounded-circle mx-auto mt-3">
              <img [src]="service.img" width="70" />
            </div>
            <span class="mt-3">{{ service.title }}</span>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
