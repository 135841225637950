<section class="section gradient">
  <div class="container">
    <div class="row text-center my-5">
      <div class="col-12">
        <div class="our-story">
          <h2>We’d love to hear from you</h2>
          <p>
            At Wellbe, we take the guesswork out of finding the right solution
            to all your health problems.
          </p>
        </div>

        <app-connect-with></app-connect-with>
      </div>
    </div>

    <div class="row text-center my-5">
      <div class="col-12">
        <div class="p-5">
          <img src="assets/img/Frame.png" class="img-fluid" alt="globe" />
        </div>
      </div>
    </div>

    <div class="row text-center my-5">
      <div class="col-12 mb-4 font-weight-bold">
        Redefining Wellbeing Pvt. Ltd.
      </div>
      <div class="col-md-6 mb-4">
        <p>
          Address: B1/H3, MOHAN CO-OPERATIVE, Mathura Rd, Industrial Area, Block
          B, New Delhi, Delhi 110044
        </p>
      </div>
      <div class="col-md-6 mb-4">
        <p>
          Address:6th Floor,Agnel Technical Complex,Sector 9A,Vashi,Navi Mumbai,
          Navi Mumbai, Maharashtra 400703
        </p>
      </div>
      <div class="col-12 mb-4">
        <p>m: +91 90350 33712 – 98400 42342</p>
        <p>e: redwellbe@gmail.com – dr.ksiddharth@wellbe.fit</p>
      </div>
    </div>

    <div class="row text-center my-5">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.755720553046!2d77.29209337566833!3d28.516996375728027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce79554f56141%3A0x78d3b28c803071b2!2s91springboard%20Mohan%20Estate!5e0!3m2!1sen!2sin!4v1692005369176!5m2!1sen!2sin"
        height="500"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        class="m-auto w-100"
      ></iframe>
    </div>

    <div class="row text-center my-5">
      <div class="col-12">
        <app-enquiry-form></app-enquiry-form>
      </div>
    </div>
  </div>
</section>
