<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
/>
<link
  href="https://fonts.googleapis.com/css2?family=Headland+One&display=swap"
/>

<header>
  <div class="header-top">
    <div class="header-logo">
      <div></div>
      <img
        src="assets/img/Wellbe Logo.png"
        class="header-logo-background img-fluid"
      />
      <div class="logo text-center position-relative">
        <a routerLink="/">
          <img src="assets/img/Wellbe-Logo_Artboard-4.png" />
        </a>
      </div>
      <div class="menu-icon d-block d-lg-none">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon">
            <img src="assets/img/icon _menu 1_.png" />
          </span>
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end flex-wrap">
      <div class="nav-item">
        <a
          class="nav-link text-dark font-weight-bold"
          routerLink="/distributor"
          style="font-size: 20px"
        >
          Distributor
        </a>
      </div>

      <div class="nav-item">
        <a
          class="nav-link text-dark font-weight-bold"
          routerLink="/contact-us"
          style="font-size: 20px"
        >
          Contact Us
        </a>
      </div>

      <div class="nav-item d-flex">
        <a
          class="nav-link"
          href="https://www.facebook.com/wellbe20"
          target="_blank"
        >
          <img
            src="assets/img/social/facebook.png"
            style="width: 30px; height: 30px"
          />
        </a>
        <a class="nav-link disabled">
          <img
            src="assets/img/social/instagram.png"
            style="width: 30px; height: 30px"
          />
        </a>
        <a
          class="nav-link"
          href="https://wa.me/message/ZJ6WYZ7UYVEGO1"
          target="_blank"
        >
          <img
            src="assets/img/social/whatsapp.png"
            style="width: 30px; height: 30px"
          />
        </a>
        <a
          class="nav-link"
          href="https://www.linkedin.com/company/31518091/admin/feed/posts/"
          target="_blank"
        >
          <img
            src="assets/img/social/linkedin.png"
            style="width: 30px; height: 30px"
          />
        </a>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg menu-bar">
      <!-- <a class="navbar-brand" href="#"></a> -->

      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/corporate"> Enterprise </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/individual"> Individuals</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/practitioners">Practitioners</a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarWhoIsThisForDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Who is this for?
            </a>
            <div
              class="dropdown-menu"
              aria-labelledby="navbarWhoIsThisForDropdown"
            >
              <a class="dropdown-item" routerLink="/bespoke-solutions">
                Enterprise
              </a>
              <a class="dropdown-item" routerLink="/revolutionary-therapies">
                Hospital
              </a>
              <a class="dropdown-item" routerLink="/tech-enabled-partnership">
                Insurance Provider
              </a>
              <a class="dropdown-item" routerLink="/specialized-personalized">
                Academic Institution
              </a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarServicesDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Services
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarServicesDropdown">
              <a class="dropdown-item" routerLink="/services/b2b"> B2B </a>
              <a class="dropdown-item" routerLink="/services/b2c"> B2C </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/mental-wellness">
              Mental Wellness
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarAboutUsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About Us
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarAboutUsDropdown">
              <a class="dropdown-item" routerLink="/about-us"> Our Story </a>
              <!-- <a class="dropdown-item" routerLink="/about-us"> Founding Team </a> -->
              <a class="dropdown-item" routerLink="/partners"> Our Partners </a>
              <a class="dropdown-item disabled" routerLink="#">
                Advisory Board
              </a>
              <a class="dropdown-item disabled" routerLink="#">
                Wellbe Champions
              </a>
              <a class="dropdown-item" routerLink="/distributor">
                Become Distributor
              </a>
              <a class="dropdown-item disabled" routerLink="#"> Blog </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
