import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateComponent } from './corporate/corporate.component';
import { DietPlanComponent } from './diet-plan/diet-plan.component';
import { DistributorComponent } from './distributor/distributor.component';
import { DoshaComponent } from './dosha/dosha.component';
import { HomeComponent } from './home/home.component';
import { IndividualComponent } from './individual/individual.component';
import { MentalWellnessComponent } from './mental-wellness/mental-wellness.component';
import { PartnersComponent } from './partners/partners.component';
import { PractitionersComponent } from './practitioners/practitioners.component';
import { B2bComponent } from './services/b2b/b2b.component';
import { B2cComponent } from './services/b2c/b2c.component';
import { BespokeSolutionsComponent } from './services/bespoke-solutions/bespoke-solutions.component';
import { RevolutionaryTherapiesComponent } from './services/revolutionary-therapies/revolutionary-therapies.component';
import { SpecializedPersonalizedComponent } from './services/specialized-personalized/specialized-personalized.component';
import { TechEnabledPartnershipComponent } from './services/tech-enabled-partnership/tech-enabled-partnership.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about-us',
    component: AboutusComponent,
  },
  {
    path: 'individual',
    component: IndividualComponent,
  },
  {
    path: 'corporate',
    component: CorporateComponent,
  },
  {
    path: 'bespoke-solutions',
    component: BespokeSolutionsComponent,
  },
  {
    path: 'revolutionary-therapies',
    component: RevolutionaryTherapiesComponent,
  },
  {
    path: 'tech-enabled-partnership',
    component: TechEnabledPartnershipComponent,
  },
  {
    path: 'specialized-personalized',
    component: SpecializedPersonalizedComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'practitioners',
    component: PractitionersComponent,
  },
  {
    path: 'mental-wellness',
    component: MentalWellnessComponent,
  },
  {
    path: 'dosha',
    component: DoshaComponent,
  },
  {
    path: 'diet-plan',
    component: DietPlanComponent,
  },
  {
    path: 'partners',
    component: PartnersComponent,
  },
  {
    path: 'distributor',
    component: DistributorComponent,
  },
  {
    path: 'services/b2b',
    component: B2bComponent,
  },
  {
    path: 'services/b2c',
    component: B2cComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
