<section class="inner-banner">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5 p-0" style="z-index: 1">
        <div class="inner-banner-text">
          <h1>Are you an ambassador of holistic wellness?</h1>
          <p>
            Be a part of the fastest growing network of alternate medicine
            service providers.
          </p>
          <div class="button-grp">
            <a routerLink="/contact-us" class="tdr-button br">
              Become a Specialist
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-7 p-0">
        <img src="assets/img/practitioner-banner.png" alt="banner-image" />
      </div>
    </div>
  </div>
</section>

<section class="section gradient">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="title text-center">Join the community</h1>
        <p class="title text-center">
          Wellbe helps you connect with peers and expand your horizons.
        </p>
      </div>
    </div>
  </div>
  <app-our-service-providers-all></app-our-service-providers-all>
  <div class="mb-5">
    <app-newsletter></app-newsletter>
  </div>
</section>
