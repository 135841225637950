import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-b2c',
  templateUrl: './b2c.component.html',
  styleUrls: ['./b2c.component.scss'],
})
export class B2cComponent {
  saasBaseUrl = environment.saasBaseUrl;
}
