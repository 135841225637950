import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { DoshaService } from 'src/app/serivce/dosha.service';
import {
  FormErrorMessage,
  focusInvalidInput,
} from 'src/app/shared/custom.validators';

export interface IScore {
  total: number;
  vata: number;
  pitta: number;
  kapha: number;
  mindBodyType: string;
}

@Component({
  selector: 'dosha-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
  ],
  providers: [DoshaService],
})
export class DoshaAssessmentCommonComponent implements OnInit, OnDestroy {
  @Input() isLinear: boolean = true;
  @Output() score = new EventEmitter<IScore>();
  @Output() submitted = new EventEmitter<boolean>();
  unSubscribe$ = new Subject();
  isSubmitted: boolean = false;
  form: FormGroup;

  constructor(private _el: ElementRef) {
    this.form = new FormGroup({
      frame: new FormControl(null, [Validators.required]),
      weight: new FormControl(null, [Validators.required]),
      eyes: new FormControl(null, [Validators.required]),
      complexion: new FormControl(null, [Validators.required]),
      hair: new FormControl(null, [Validators.required]),
      joints: new FormControl(null, [Validators.required]),
      sleepPattern: new FormControl(null, [Validators.required]),
      bodyTemperature: new FormControl(null, [Validators.required]),
      temprament: new FormControl(null, [Validators.required]),
      underStress: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unSubscribe$.next(null);
    this.unSubscribe$.complete();
  }

  // Form error messages
  getErrorMessage(control: string, formGroup?: FormGroup): string | null {
    if (this.isSubmitted) {
      this.form.markAllAsTouched();
    }
    const form = formGroup ?? this.form;
    if (this.isSubmitted || form?.get(control)?.touched) {
      return FormErrorMessage(form, control);
    }
    return null;
  }

  getControlFormArray(control: string, form: FormGroup): FormArray {
    return form.get(control) as FormArray;
  }

  getControlFormGroup(control: string, form: FormGroup): FormGroup {
    return form.get(control) as FormGroup;
  }

  getControl(control: string): FormControl {
    return this.form.get(control) as FormControl;
  }

  validateForm(form: FormGroup): boolean {
    form.markAllAsTouched();
    this.isSubmitted = true;
    if (form.invalid) {
      focusInvalidInput(this._el);
    }
    return form.valid;
  }

  submit(): void {
    this.isSubmitted = true;
    this.submitted.emit(true);
    this.calculateDosha();
  }

  maxOfArray(array: number[]) {
    return Math.max.apply(Math, array);
  }

  calculateDosha(): IScore {
    let total = 0;
    let totalVata = 0;
    let totalPitta = 0;
    let totalKapha = 0;

    Object.keys(this.form.value).forEach((key) => {
      let value = this.form.value[key];
      total += 10;
      if (value == 'Vata') {
        totalVata += 10;
      } else if (value == 'Pitta') {
        totalPitta += 10;
      } else if (value == 'Kapha') {
        totalKapha += 10;
      }
    });
    var score: IScore = {
      total: totalVata + totalPitta + totalKapha,
      vata: totalVata,
      pitta: totalPitta,
      kapha: totalKapha,
      mindBodyType:
        totalVata > totalPitta && totalVata > totalKapha
          ? 'Vata'
          : totalPitta > totalVata && totalPitta > totalKapha
          ? 'Pitta'
          : 'Kapha',
    };

    this.score.emit(score);
    return score;
  }
}
