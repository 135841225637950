<section class="section gradient" id="section-ptsd">
  <div class="container">
    <section class="inner-banner">
      <div class="row">
        <div class="col-lg-5 my-3">
          <h1>Post-traumatic stress disorder (PTSD)</h1>
          <p>
            Virtual Reality (VR) immersive therapy has shown promising potential
            in treating individuals with Post-Traumatic Stress Disorder (PTSD).
            PTSD is a mental health condition that can develop after
            experiencing or witnessing a traumatic event, and it often involves
            symptoms like intrusive memories, nightmares, hyperarousal, and
            avoidance behaviors. Traditional forms of therapy, such as talk
            therapy and exposure therapy, have been effective, but VR therapy
            offers a unique and innovative approach to address PTSD symptoms.
          </p>
          <p>
            Here's how VR immersive therapy can play a role in treating these
            conditions:
          </p>
          <div class="row features">
            <div class="col-md-6">
              <p><i class="fa fa-check-circle mr-2"></i> Safe Exposure</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Realistic Replication
              </p>
              <p><i class="fa fa-check-circle mr-2"></i> Customization</p>
              <p><i class="fa fa-check-circle mr-2"></i> Emotion Regulation</p>
            </div>
            <div class="col-md-6">
              <p><i class="fa fa-check-circle mr-2"></i> Sense of Control</p>
              <p><i class="fa fa-check-circle mr-2"></i> Recontextualization</p>
              <p><i class="fa fa-check-circle mr-2"></i> Reduced Stigma</p>
              <p>
                <i class="fa fa-check-circle mr-2"></i> Engagement and
                Motivation
              </p>
            </div>
          </div>
          <div class="button-grp mt-4">
            <a [href]="saasBaseUrl" target="_blank" class="tdr-button br">
              Get Started
            </a>
          </div>
        </div>
        <div
          class="col-lg-7 my-3 d-flex justify-content-center align-items-center"
        >
          <img
            class="pill-image w-100 flip"
            style="height: 450px"
            src="assets/img/mental-wellness/ptsd.jpg"
            alt="banner-image"
          />
        </div>
      </div>
    </section>

    <div class="row" style="margin-top: 70px">
      <div
        class="col-lg-3 col-md-4 col-sm-6"
        [ngClass]="{ 'my-3': i < 4 || (i >= 4 && showMoreServices) }"
        *ngFor="let service of services; let i = index"
      >
        <div
          class="card p-3 services h-100"
          *ngIf="i < 4 || (i >= 4 && showMoreServices)"
        >
          <div class="ptsd text-center">
            <div class="rounded-circle mx-auto mt-3">
              <img [src]="service.img" width="70" />
            </div>
            <span class="mt-3">{{ service.title }}</span>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button
          class="btn btn-link"
          style="color: #766f6f"
          (click)="showMoreServices = !showMoreServices"
        >
          {{ showMoreServices ? "<< Less" : "More >>" }}
        </button>
      </div>
    </div>
  </div>
</section>
