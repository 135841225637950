import { Component } from '@angular/core';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent {
  modules: {
    title: string;
    description: string;
    img: string;
    link?: string;
  }[] = [
    {
      title: '',
      description:
        'Gamified model to treat mental illnesses. Highly recommended for patients suffering from phobias, fear, anger, anxiety, depression and bipolar disorder.',
      img: 'assets/img/anx1.webp',
    },
    {
      title: '',
      description:
        'Platform is now powered by Virtual Talk Guide which lead healing sessions within virtual worlds. System re-create a virtual & real-time 3D photorealistic safe environment in which to “expose” individuals to the things they fear and avoid',
      img: 'assets/img/anx1.webp',
    },
    {
      title: '',
      description:
        'Distribute your fully branded white / private label applications to end users / participants. Treat patients from all over the globe.',
      img: 'assets/img/anx1.webp',
    },
    {
      title: '',
      description:
        'Platform can be easily integrated with your present traditional therapy practices. Get better results with the state of the art Teletherapy & VTG Platform.',
      img: 'assets/img/anx1.webp',
    },
  ];
}
