import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ptsd',
  templateUrl: './ptsd.component.html',
  styleUrls: ['./ptsd.component.scss'],
})
export class PtsdComponent {
  saasBaseUrl = environment.saasBaseUrl;
  showMoreServices = false;
  services: {
    title: string;
    description: string;
    img: string;
    link?: string;
  }[] = [
    {
      title: 'Family abuse',
      description: 'Violence',
      img: 'assets/img/ptsd/PTSD-family-abuse.svg',
    },
    {
      title: 'Sexual assault',
      description: 'Violence',
      img: 'assets/img/ptsd/PTSD-parent-violence.svg',
    },
    {
      title: 'Brawl',
      description: 'Violence',
      img: 'assets/img/ptsd/PTSD-brawl.svg',
    },
    {
      title: 'Bullying at School',
      description: 'Violence',
      img: 'assets/img/ptsd/PTSD-bullying.svg',
    },
    {
      title: 'Bullying at University',
      description: 'Violence',
      img: 'assets/img/ptsd/Bullying_at_university.png',
    },
    {
      title: 'Earthquake',
      description: 'Natural disaster',
      img: 'assets/img/ptsd/PTSD-Earthquake.svg',
    },
    {
      title: 'Flood',
      description: 'Natural disaster',
      img: 'assets/img/ptsd/PTSD-flood.svg',
    },
    {
      title: 'Storm',
      description: 'Natural disaster',
      img: 'assets/img/ptsd/PTSD-Storm.svg',
    },
    {
      title: 'Fire Accidents',
      description: 'Technological disaster',
      img: 'assets/img/ptsd/PTSD-Burned.svg',
    },
    {
      title: 'Terrorism',
      description: 'Terrorist attacks',
      img: 'assets/img/ptsd/PTSD-Terrorism.svg',
    },
    {
      title: 'Car Accident',
      description: 'Traffic collisions',
      img: 'assets/img/ptsd/PTSD-Car-Fire.svg',
    },
    {
      title: 'Military (Aircraft/vehicle)',
      description: 'War',
      img: 'assets/img/ptsd/military_vehicle.png',
    },
    {
      title: 'War consequences',
      description: 'War',
      img: 'assets/img/ptsd/PTSD-war-consequence.svg',
    },
  ];

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };
}
