import { Component } from '@angular/core';

@Component({
  selector: 'app-why-mental-health',
  templateUrl: './why-mental-health.component.html',
  styleUrls: ['./why-mental-health.component.scss'],
})
export class WhyMentalHealthComponent {
  solutions: {
    title: string;
    description: string;
    img: string;
    link?: string;
    fragment?: string;
  }[] = [
    {
      title: 'Anxiety Disorder',
      description:
        'Anxiety disorder due to a medical condition includes symptoms of intense anxiety or panic that are directly caused by a physical health problem. Generalized anxiety disorder includes persistent and excessive anxiety and worry about activities or events — even ordinary, routine issues.',
      img: 'assets/img/mental-wellness/anxiety.avif',
      fragment: 'section-anxiety-disorder',
    },
    {
      title: 'Post-Traumatic Stress Disorder',
      description:
        "Post-traumatic stress disorder (PTSD) is a mental health condition that's triggered by a terrifying event — either experiencing it or witnessing it. Symptoms may include flashbacks, nightmares and severe anxiety, as well as uncontrollable thoughts about the event.",
      img: 'assets/img/mental-wellness/mw-2.webp',
      fragment: 'section-ptsd',
    },
    {
      title: 'Wellness',
      description:
        'Virtual Reality (VR) immersive therapy is increasingly being recognized as a valuable tool for promoting wellness, relaxation, and mindful meditation. By leveraging the immersive and interactive capabilities of VR technology, individuals can be transported to serene environments, engage in guided meditation, and experience a heightened sense of presence.',
      img: 'assets/img/mental-wellness/nature.avif',
      fragment: 'section-wellness',
    },
  ];

  scrollToSection(fragment: string) {
    document.getElementById(fragment)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
