import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss'],
})
export class IndividualComponent {
  saasBaseUrl = environment.saasBaseUrl;
  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
  };

  courses: { imageSrc: string }[] = [
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
    {
      imageSrc: 'assets/img/explore-course.png',
    },
  ];
}
