import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiListResponse } from '../models/api-res.model';
import { LookupResModel } from '../models/lookup.model';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private _httpClient: HttpClient) {}
  GetAll(param: HttpParams): Observable<IApiListResponse<LookupResModel>> {
    return this._httpClient.get<IApiListResponse<LookupResModel>>(
      `${environment.apiUrl}/g-master/v1.0/lookup`,
      { params: param }
    );
  }
}
