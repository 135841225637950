<div class="about-banner">
  <img src="assets/img/about_ban.jpg" class="img-fluid" />
  <div class="text_heading">
    <h2>About Us</h2>
  </div>
</div>

<div class="our-story">
  <h2>Our Story</h2>
  <p>
    Our mission - from even before Wellbe was born - is to make expert
    healthcare accessible and holistic wellness possible for all.
  </p>
</div>

<!------------about timeline---->
<section class="about-timeline gradient">
  <div class="container">
    <!-- Vertical Timeline -->
    <div id="conference-timeline">
      <div class="conference-center-line"></div>
      <div class="conference-timeline-content">
        <!-- Article -->
        <div class="timeline-article">
          <div class="content-left-container">
            <div class="content-left about-img ab-before1">
              <img src="assets/img/n1.png" />
            </div>
          </div>
          <div class="content-right-container">
            <div class="content-right m-pt-0">
              <p>
                A Manipal University incubated and award-winning Dentogate
                pivoted to HelloDoc - a healthcare aggregator focused on
                personalized and preventive care through teleconsultation.
              </p>
              <p>
                Dr. Siddharth was awarded the first ever NIDHI-EIR (Entrepreneur
                in Residence), a fellowship program designed by the Department
                of Science & Technology to support budding entrepreneurs. The
                story was featured under "changemakers of the year" by India
                Today
              </p>
            </div>
          </div>
          <div class="meta-date active">
            <span class="month m-right">2013-2017</span>
          </div>
        </div>
        <!-- // Article -->

        <!-- Article -->
        <div class="timeline-article a-1">
          <div class="content-left-container">
            <div class="content-left">
              <p>
                Our team's extensive experience in the health tech sector was a
                key motivator. We've engaged in numerous foundational
                experiments, leading to our current, potentially transformative
                model for global workforce wellness. Personally, the journey
                took a toll on our own wellbeing and mental health. The
                resilience and mental strategies developed during this period
                not only helped us overcome challenges but also kept our vision
                alive. These experiences have shaped our innovative wellbeing
                models, combining practical health strategies with a sense of
                spiritual well-being.
              </p>
            </div>
          </div>
          <div class="content-right-container">
            <div class="content-right about-img ab-before2">
              <img src="assets/img/n2.png" />
            </div>
          </div>
          <div class="meta-date">
            <span class="month m-left">2017-2019</span>
          </div>
        </div>
        <!-- // Article -->

        <!-- Article -->
        <div class="timeline-article">
          <div class="content-left-container">
            <div class="content-left about-img ab-before3">
              <img src="assets/img/n3.png" />
            </div>
          </div>
          <div class="content-right-container">
            <div class="content-right m-pt-0">
              <p>
                The pandemic accelerated the growth of Wellbe, skyrocketing the
                popularity of on-demand alternative medicine. The number of
                people who needed these services expanded and so did the network
                of service providers on the platform.
              </p>
              <p>
                We developed our AI-based patient-service provider-matching
                system that routes users to the right specialist for the
                concerned health issues. It was around this time that the entire
                entrepreneurial journey got featured in the reputed online media
                covering startups and founders' journey called Yourstory.com
              </p>
            </div>
          </div>
          <div class="meta-date">
            <span class="month m-right">2020</span>
          </div>
        </div>
        <!-- Article -->
        <div class="timeline-article">
          <div class="content-left-container">
            <div class="content-left">
              <p>
                Wellbe deployed its tech platform and ran the complete
                implementation of COVID-19 relief Telehealth vaccination and
                teleconsultation support in the UT of Daman & Diu in
                collaboration with American India Foundation Trust, eSanjeevani
                AYUSHMAN BHARAT, supported by Sterlite Technologies.
              </p>
              <p>
                The impact was massive as Wellbe ended up catering to 59, 000+
                beneficiaries in a span of 1 year of conducting this program.
              </p>
            </div>
          </div>
          <div class="content-right-container">
            <div class="content-right about-img ab-before4">
              <img src="assets/img/n4.png" />
            </div>
          </div>
          <div class="meta-date">
            <span class="month m-left">2021</span>
          </div>
        </div>
        <!-- Article -->
        <div class="timeline-article">
          <div class="content-left-container">
            <div class="content-left about-img ab-before5">
              <img src="assets/img/n5.png" />
            </div>
          </div>
          <div class="content-right-container">
            <div class="content-right m-pt-0">
              <p>
                We started collaborating with enterprises to cater to employees
                dealing with preventable chronic conditions, reversible
                non-communicable diseases and health issues caused by a
                sedentary lifestyle. Our technology lets HR professionals
                measure the impact of their team’s health on the company’s
                bottom line.
              </p>
              <p>
                Our efforts were recognized and we won multiple awards.
                Additionally. Wellbe earned a DST supported (Department of
                Science & Technology) NIDHI-SSP institutional fundraise from
                CIBA (Center for Incubation and Business Acceleration)
                Foundation Mumbai.
              </p>
            </div>
          </div>
          <div class="meta-date">
            <span class="month m-right">2022</span>
          </div>
        </div>
        <!-- Article -->
        <div class="timeline-article">
          <div class="content-left-container">
            <div class="content-left">
              <p>
                Wellbe is expanding its services to make holistic wellness
                accessible for all. We now have B2B software license for virtual
                reality exposure therapy. Moreover we are expanding our service
                categories to cater to more diverse industries.
              </p>
            </div>
          </div>
          <div class="content-right-container">
            <div class="content-right about-img ab-before6">
              <img src="assets/img/n6.png" />
            </div>
          </div>
          <div class="meta-date">
            <span class="month m-left">2023</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gradient">
  <div class="our-associations">
    <div class="our-story">
      <h2>Our Associations</h2>
      <div class="mx-5 p-5">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of associations">
            <ng-template carouselSlide>
              <img [style.width]="item.width" [src]="item.imageSrc" />
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>

  <div class="our-associations">
    <div class="our-story py-0">
      <h2>Our Partners</h2>
      <div class="mx-5 p-5">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of partners">
            <ng-template carouselSlide>
              <img [style.width]="item.width" [src]="item.imageSrc" />
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>

  <div class="contact-us">
    <h1>Connect with us</h1>
    <a
      class="btn"
      href="https://calendly.com/dr-ksiddharth/product-demo"
      target="_blank"
    >
      Schedule a call
    </a>
  </div>
</section>
