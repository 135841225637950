import { Component } from '@angular/core';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
})
export class VideosComponent {
  showMoreVideos = false;
  videos: { title: string; src: string }[] = [
    {
      title: 'Insectophobia',
      src: 'assets/videos/Insectophobia_2_Ligtning.mp4',
    },
    {
      title: 'Acrophobia',

      src: 'assets/videos/Acrophobia_Skyscrapper_3.mp4',
    },
    {
      title: 'Arachnophobia',

      src: 'assets/videos/Arachnophobia_Exhibition_2.mp4',
    },
    {
      title: 'Aviaphobia',

      src: 'assets/videos/Aviaphobia_Airport_3.mp4',
    },
    {
      title: 'Germophobia',

      src: 'assets/videos/Germophobia_4.mp4',
    },
    {
      title: 'Kinophobia',

      src: 'assets/videos/Kinophobia_1.mp4',
    },
    {
      title: 'Mysophobia',

      src: 'assets/videos/Mysophobia_3.mp4',
    },
    {
      title: 'NeuroGraphics',

      src: 'assets/videos/NeuroGraphics_1.mp4',
    },
  ];
}
