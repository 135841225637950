<div class="container mt-4">
  <div class="row mb-5">
    <div class="col-md-12">
      <h1 class="title text-center">Our Mobile App</h1>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-md-4 col-12">
      <img src="assets/img/app-fit.png" alt="app shot" />
    </div>
    <div class="col-md-8 col-12 hr-dashboard">
      <div class="flex">
        <p class="Wellbe">Wellbe.fit mobile App available on both</p>
        <a href="#" class="mr-3">
          <img src="assets/img/play.png" alt="play Store icon" />
        </a>
        <a href="#" class="mr-3">
          <img src="assets/img/apply.png" alt="Apple App Store icon"
        /></a>
        <a [href]="saasBaseUrl + '/sign-in'" target="_blank" class="tdr-button">
          Web App
        </a>
      </div>
      <h1 class="mt-5 text-center">HR Dashboard</h1>
      <img
        class="hr-dashboard-img"
        src="assets/img/hr-dashboard.png"
        alt="Hr dashboard"
      />
      <div class="flex">
        <p>360 degree solution to enterprise employee wellbeing.</p>
        <p>
          Corporate Dashboard for HR to see employee engagement and handle
          services.
        </p>
      </div>
    </div>
  </div>
</div>
