import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent {
  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
  };

  testimonials: {
    service: string;
    userImg: string;
    userName: string;
    userDesignation?: string;
    testimonial: string;
  }[] = [
    {
      service: 'Wellbe',
      userImg: 'assets/img/user.png',
      userName: 'American India Foundation Trust',
      testimonial:
        'WellBe was instrumental in handling very complex health and operational related scenarios at the time of conducting Covid Relief telehealth & wellness program. Right from technology deployment to actual implementation to eventually partnering with Government’s flagship health initiative eSanjeevani AYUSHMAN BHARAT, WellBe has created a massive value and impact to the citizens and all the stakeholders by offering services to 59, 000+ beneficiaries at a very critical time of Covid 19 pandemic.',
    },
    {
      service: 'Wellbe',
      userImg: 'assets/img/user.png',
      userName: 'ABS Packaging',
      testimonial:
        "WellBe's scientific framework of making tailor made custom package for the organization and for the employees was unique in achieving positive health outcomes and proved to be a great insight for organizations looking at long term wellness as strategy for driving performance and profitability",
    },
    {
      service: 'Wellbe',
      userImg: 'assets/img/user.png',
      userName: 'Irwin Noland',
      testimonial:
        "WellBe app has helped me improve my focus and productivity at work, I can't imagine starting my day without this app",
    },
    {
      service: 'Wellbe',
      userImg: 'assets/img/user.png',
      userName: 'Stephens Kamila',
      testimonial:
        'I have been using this WellBe app for quite sometime now and it has made me more aware and spiritual my health & life in general ',
    },
    {
      service: 'Wellbe',
      userImg: 'assets/img/user.png',
      userName: 'Anushka Shankar',
      testimonial: 'Wonderful experience, must try this WellBe app',
    },
    {
      service: 'Wellbe',
      userImg: 'assets/img/user.png',
      userName: 'Uddeshya Thakur',
      testimonial:
        'I really like the connect to expert feature on the app, it helped me seamlessly and quickly connect with the right wellness expert for my problem',
    },
  ];
}
