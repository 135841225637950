<section class="section gradient">
  <div class="container">
    <h1 class="text-center">Why Mental Health</h1>
    <section class="inner-banner">
      <div class="row">
        <div class="col-lg-7 my-3">
          <img
            class="border-radius-50"
            src="assets/img/mental-wellness/why-mental-wellness.avif"
            alt="banner-image"
          />
        </div>
        <div class="col-lg-5 my-3">
          <p>
            Mental health is a state of mental well-being that enables people to
            cope with the stresses of life, realize their abilities, learn well
            and work well, and contribute to their community. It is an integral
            component of health and well-being that underpins our individual and
            collective abilities to make decisions, build relationships and
            shape the world we live in. Mental health is a basic human right.
            And it is crucial to personal, community and socio-economic
            development.
          </p>

          <ul>
            <li>
              The World Bank report (1993) revealed that the Disability Adjusted
              Life Year (DALY) loss due to neuropsychiatric disorder is much
              higher than diarrhea, malaria, worm infestations and tuberculosis
              if taken individually.
            </li>
            <li>
              Globally, as of 2015, there were over 322.48 million people with
              some form of depressive disorder.
            </li>
            <li>
              In 2017, more than 14 percent of India's total population suffered
              from various mental disorders.
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="inner-banner mt-5">
      <div class="row">
        <div class="col-lg-5 my-3">
          <h3>Problems We Are Addressing</h3>
          <p>
            Mental health has been increasingly recognized as a significant
            issue both in India and overseas, and the COVID-19 pandemic has
            exacerbated the challenges, leading to the term "pandemic within the
            pandemic."
          </p>

          <ul>
            <li>
              Mental health issues (anxiety, depression, PTSD, substance use
              disorders, and phobias) are significant concerns globally,
              exacerbated by the COVID-19 pandemic.
            </li>
            <li>
              These problems profoundly impact individuals' lives,
              relationships, and overall well-being.
            </li>
            <li>
              Research links depression to an increased risk of diabetes,
              emphasizing the need for integrated healthcare approaches.
            </li>
            <li>
              Addressing mental health is crucial for reducing the burden on
              individuals, families, and society.
            </li>
          </ul>
        </div>
        <div class="col-lg-7 my-3">
          <img
            class="border-radius-50"
            src="assets/img/mental-wellness/problems-addressing.jpg"
            alt="banner-image"
          />
        </div>
      </div>
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="col-12 my-3">
          <h3>Solutions We Deliver</h3>
        </div>
        <div
          class="col-lg-4 col-sm-6 my-3"
          *ngFor="let solution of solutions; let i = index"
        >
          <div
            class="card p-3 solutions h-100 cursor-pointer"
            (click)="scrollToSection(solution.fragment ?? '')"
          >
            <div class="text-center">
              <img
                [src]="solution.img"
                class="rounded-circle mx-auto mt-3"
                width="200"
                height="200"
              />
              <p class="h4 mt-3 font-weight-bold">{{ solution.title }}</p>
              <p class="text-justify">{{ solution.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
