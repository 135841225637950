<section class="section gradient" id="section-videos">
  <div class="container">
    <h1 class="text-center">Videos</h1>

    <div class="row mt-5">
      <div
        class="col-lg-4 col-sm-6"
        [ngClass]="{ 'my-3': i < 3 || (i >= 3 && showMoreVideos) }"
        *ngFor="let video of videos; let i = index"
      >
        <div
          class="card p-3 videos h-100"
          *ngIf="i < 3 || (i >= 3 && showMoreVideos)"
        >
          <div class="ptsd text-center">
            <div class="rounded-circle mx-auto mt-3">
              <video controls class="w-100">
                <source [src]="video.src | safe" type="video/mp4" />
              </video>
            </div>
            <span class="mt-3">{{ video.title }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button
          class="btn btn-link"
          style="color: #766f6f"
          (click)="showMoreVideos = !showMoreVideos"
        >
          {{ showMoreVideos ? "<< Less" : "More >>" }}
        </button>
      </div>
    </div>
  </div>
</section>
