import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare var jQuery: any;
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class AboutusComponent {
  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    margin: 100,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };

  associations: { imageSrc: string; width?: string }[] = [
    {
      imageSrc: 'assets/img/associations/asso-1.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-2.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-3.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-4.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-5.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-6.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-7.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-9.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-10.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-11.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-13.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-14.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-15.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-16.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-17.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-18.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/associations/asso-19.png',
      width: '400px',
    },
  ];

  partners: { imageSrc: string; width?: string }[] = [
    {
      imageSrc: 'assets/img/partnership/partner-4.png',
      width: '400px',
    },
    // {
    //   imageSrc: 'assets/img/partnership/partner-5.png',
    //   width: '400px',
    // },
    {
      imageSrc: 'assets/img/partnership/partner-3.png',
      width: '100px',
    },
    {
      imageSrc: 'assets/img/partnership/partner-1.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/partnership/partner-6.png',
      width: '100px',
    },
    {
      imageSrc: 'assets/img/partnership/partner-7.png',
      width: '400px',
    },
    {
      imageSrc: 'assets/img/partnership/partner-8.png',
      width: '400px',
    },
  ];
}
