import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.scss'],
})
export class DistributorComponent {
  saasBaseUrl = environment.saasBaseUrl;
}
