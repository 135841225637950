<div class="mt-4">
  <!-- <h1 class="title text-center">Our Story</h1> -->
  <div class="container-fluid p-0">
    <iframe
      [src]="storyboardFile | safe"
      frameborder="0"
      class="w-100"
      style="height: 700px"
    ></iframe>
  </div>
  <div class="container">
    <div class="video-content text-center my-4">
      <h4>Check your Wellbe score</h4>
      <p>
        Get a complete picture of your health, likelihood of disease and chronic
        illnesses and nutrition requirements according to your unique mind-body
        type.
      </p>
      <a
        [href]="saasBaseUrl + '/open/organizational-assessment'"
        target="_blank"
        class="tdr-button main"
        >Check your score</a
      >
    </div>
  </div>
</div>
