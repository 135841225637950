<section class="bg-img">
  <div class="container main-container py-5">
    <form id="form" [formGroup]="form" autocomplete="off" *ngIf="!dietPlanUrl">
      <div class="box">
        <div *ngIf="state === 1" class="w-80" @fade>
          <h1>
            <strong>
              “Transform your life with a Smart Diet Plan - Your Personalized AI
              Nutrition coach!!”
            </strong>
          </h1>

          <p style="font-size: 30px">
            <strong> Now eat smart with AI insights!! </strong>
          </p>

          <div class="mt-4">
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="next()"
            >
              Click here to start
            </button>
          </div>
        </div>

        <div *ngIf="state === 2" class="w-80" @fade>
          <h1>
            <span>
              “Eating smart is the key to a long, healthy & happier life.”
            </span>
          </h1>

          <h3 style="font-size: 30x">
            It's not about eating less it's about eating right. Try our Smart
            Diet Plan today!
          </h3>

          <div class="mt-4">
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="next()"
            >
              Continue
            </button>
          </div>
        </div>

        <div *ngIf="state === 3" class="w-80" @fade>
          <h2>What is your email?</h2>
          <mat-form-field class="w-full" appearance="fill">
            <input matInput formControlName="email" />
            <mat-error>
              {{ getErrorMessage("email") }}
            </mat-error>
          </mat-form-field>

          <div class="mt-4">
            <button
              type="button"
              mat-flat-button
              color="primary"
              [disabled]="!form.get('email')?.valid"
              (click)="isRegisteredUser(form.value.email)"
            >
              Next <mat-icon>check</mat-icon>
            </button>
            &nbsp;
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="prev()"
            >
              Back
            </button>
          </div>
        </div>

        <div *ngIf="state === 4" class="w-80" @fade>
          <h2>What is your mobile number?</h2>
          <mat-form-field class="w-full" appearance="fill">
            <input matInput formControlName="mobile" />
            <mat-error>
              {{ getErrorMessage("mobile") }}
            </mat-error>
          </mat-form-field>

          <div class="mt-4">
            <button
              type="button"
              mat-flat-button
              color="primary"
              [disabled]="!form.get('mobile')?.valid"
              (click)="isRegisteredUser(form.value.mobile)"
            >
              Next <mat-icon>check</mat-icon>
            </button>
            &nbsp;
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="prev()"
            >
              Back
            </button>
          </div>
        </div>

        <div *ngIf="state === 5" class="w-80" @fade>
          <h2>Hey, nice to meet you. What is your first name ?</h2>
          <mat-form-field class="w-full" appearance="fill">
            <input matInput formControlName="firstName" />
            <mat-error>
              {{ getErrorMessage("firstName") }}
            </mat-error>
          </mat-form-field>

          <div class="mt-4">
            <button
              type="button"
              mat-flat-button
              color="primary"
              [disabled]="!form.get('firstName')?.valid"
              (click)="next()"
            >
              Next <mat-icon>check</mat-icon>
            </button>
            &nbsp;
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="prev()"
            >
              Back
            </button>
          </div>
        </div>

        <div *ngIf="state === 6" class="w-80" @fade>
          <h2>Great to meet you! What is your last name?</h2>
          <mat-form-field class="w-full" appearance="fill">
            <input matInput formControlName="lastName" />
            <mat-error>
              {{ getErrorMessage("lastName") }}
            </mat-error>
          </mat-form-field>

          <div class="mt-4">
            <button
              type="button"
              mat-flat-button
              color="primary"
              [disabled]="!form.get('lastName')?.valid"
              (click)="next()"
            >
              Next <mat-icon>check</mat-icon>
            </button>
            &nbsp;
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="prev()"
            >
              Back
            </button>
          </div>
        </div>

        <ng-container formGroupName="corporate">
          <ng-container formGroupName="basicProfile">
            <div *ngIf="state === 7" class="w-80" @fade>
              <h2>What is your DOB ?</h2>
              <mat-form-field class="w-full" appearance="fill">
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="dob"
                  autocomplete="off"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>
                  {{
                    getErrorMessage(
                      "dob",
                      $any(getForm("corporate").get("basicProfile"))
                    )
                  }}
                </mat-error>
              </mat-form-field>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('basicProfile', getForm('corporate')).get('dob')
                      ?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 8" class="w-80" @fade>
              <h2>What is your gender?</h2>

              <div class="custom-radio-container">
                <label class="custom-radio" *ngFor="let each of genderArr">
                  <input
                    type="radio"
                    formControlName="gender"
                    [value]="each.value"
                  />
                  <span class="radio-btn">
                    <div class="content">
                      <h4>
                        {{ each.title }}
                      </h4>
                    </div>
                  </span>
                </label>
                <mat-error>
                  {{
                    getErrorMessage(
                      "gender",
                      $any(getForm("corporate").get("basicProfile"))
                    )
                  }}
                </mat-error>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('basicProfile', getForm('corporate')).get('gender')
                      ?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 9" class="w-80" @fade>
              <h2>What is your height?</h2>
              <div class="d-flex">
                <mat-form-field class="w-full mr-1" appearance="fill">
                  <mat-label>Feet</mat-label>
                  <mat-select
                    [(ngModel)]="heightFt"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="calculateCm()"
                  >
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let each of heightFtArr" [value]="each">
                      {{ each }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full ml-1" appearance="fill">
                  <mat-label>Inch</mat-label>
                  <mat-select
                    [(ngModel)]="heightInch"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="calculateCm()"
                  >
                    <mat-option>None</mat-option>
                    <mat-option
                      *ngFor="let each of heightInchArr"
                      [value]="each"
                    >
                      {{ each }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <h2 class="text-center">or</h2>

              <mat-form-field class="w-full" appearance="fill">
                <mat-label>CM</mat-label>
                <input
                  matInput
                  type="number"
                  min="0"
                  max="244"
                  formControlName="heightInCm"
                  (change)="calculateFtInch()"
                />
                <mat-error>
                  {{
                    getErrorMessage(
                      "heightInCm",
                      $any(getForm("corporate").get("basicProfile"))
                    )
                  }}
                </mat-error>
              </mat-form-field>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('basicProfile', getForm('corporate')).get(
                      'heightInCm'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 10" class="w-80" @fade>
              <h2>What is your weight (In KG)</h2>
              <mat-form-field class="w-full" appearance="fill">
                <input
                  matInput
                  type="number"
                  min="0"
                  max="150"
                  formControlName="weightInKg"
                />
                <mat-error>
                  {{
                    getErrorMessage(
                      "weightInKg",
                      $any(getForm("corporate").get("basicProfile"))
                    )
                  }}
                </mat-error>
              </mat-form-field>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('basicProfile', getForm('corporate')).get(
                      'weightInKg'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 11" class="w-80" @fade>
              <h2>What is your country?</h2>
              <mat-form-field class="w-full" appearance="fill">
                <mat-select formControlName="countryId" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Country"
                      [formControl]="countryFilterCtrl"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let each of filteredCountries | async"
                    [value]="each.id"
                  >
                    {{ each.name }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{
                    getErrorMessage(
                      "countryId",
                      $any(getForm("corporate").get("basicProfile"))
                    )
                  }}
                </mat-error>
              </mat-form-field>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('basicProfile', getForm('corporate')).get(
                      'countryId'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container formGroupName="dietPlannerProfile">
            <div *ngIf="state === 12" class="w-80" @fade>
              <h2>Your goal for the Diet Plan</h2>

              <div class="custom-radio-container">
                <label
                  class="custom-radio"
                  *ngFor="let each of dietPlannerTypes"
                >
                  <input
                    type="radio"
                    formControlName="dietType"
                    [value]="each.value"
                  />
                  <span class="radio-btn">
                    <div class="content">
                      <h4>
                        {{ each.title?.split("(")?.at(0) }}
                      </h4>
                      <p class="mt-3">
                        {{ each.title?.split("(")?.at(1)?.replaceAll(")", "") }}
                      </p>
                    </div>
                  </span>
                </label>
                <mat-error>
                  {{
                    getErrorMessage(
                      "dietType",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'dietType'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 13" class="w-80" @fade>
              <h2>How will you describe your current lifestyle?</h2>

              <div class="custom-radio-container">
                <label
                  class="custom-radio"
                  *ngFor="let each of dietPlannerActivities"
                >
                  <input
                    type="radio"
                    formControlName="activity"
                    [value]="each.value"
                  />
                  <span class="radio-btn">
                    <div class="content">
                      <h4>
                        {{ each.title?.split("(")?.at(0) }}
                      </h4>
                      <p class="mt-3">
                        {{ each.title?.split("(")?.at(1)?.replaceAll(")", "") }}
                      </p>
                    </div>
                  </span>
                </label>
                <mat-error>
                  {{
                    getErrorMessage(
                      "activity",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'activity'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 14" class="w-80" @fade>
              <h2>What is your prefered workout time</h2>

              <div class="custom-radio-container">
                <label
                  class="custom-radio"
                  *ngFor="let each of dietPlannerPrefWorkoutTime"
                >
                  <input
                    type="radio"
                    formControlName="prefWorkoutTime"
                    [value]="each.value"
                  />
                  <span class="radio-btn">
                    <div class="content">
                      <h4>
                        {{ each.title }}
                      </h4>
                    </div>
                  </span>
                </label>
                <mat-error>
                  {{
                    getErrorMessage(
                      "prefWorkoutTime",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'prefWorkoutTime'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 15" class="w-80" @fade>
              <h2>Almost done! Please provide your community?</h2>

              <div class="custom-radio-container">
                <label
                  class="custom-radio"
                  *ngFor="let each of dietPlannerCommunities"
                >
                  <input
                    type="radio"
                    formControlName="community"
                    [value]="each.value"
                  />
                  <span class="radio-btn">
                    <div class="content">
                      <h4>
                        {{ each.title }}
                      </h4>
                    </div>
                  </span>
                </label>
                <mat-error>
                  {{
                    getErrorMessage(
                      "community",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'community'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 16" class="w-80" @fade>
              <h2>Your favourite food type ?</h2>

              <div class="custom-radio-container">
                <label
                  class="custom-radio"
                  *ngFor="let each of dietPlannerFoodTypes"
                >
                  <input
                    type="radio"
                    formControlName="foodType"
                    [value]="each.value"
                  />
                  <span class="radio-btn">
                    <div class="content">
                      <h4>
                        {{ each.title }}
                      </h4>
                    </div>
                  </span>
                </label>
                <mat-error>
                  {{
                    getErrorMessage(
                      "foodType",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'foodType'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 17" class="w-80" @fade>
              <h2>You are almost done!! Choose the diseases?</h2>

              <mat-form-field class="w-full" appearance="fill">
                <mat-select formControlName="diseases" multiple>
                  <mat-option
                    *ngFor="let each of dietPlannerDiseases"
                    [value]="each.value"
                    >{{ each.title }}</mat-option
                  >
                </mat-select>
                <mat-error>
                  {{
                    getErrorMessage(
                      "diseases",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </mat-form-field>

              <div class="mt-4">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'diseases'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 18" class="w-80" @fade>
              <h2>Last step! Choose the allergies ?</h2>

              <mat-form-field class="w-full" appearance="fill">
                <mat-select formControlName="allergies" multiple>
                  <mat-option
                    *ngFor="let each of dietPlannerAllergies"
                    [value]="each.value"
                    >{{ each.title }}</mat-option
                  >
                </mat-select>
                <mat-error>
                  {{
                    getErrorMessage(
                      "allergies",
                      $any(getForm("corporate").get("dietPlannerProfile"))
                    )
                  }}
                </mat-error>
              </mat-form-field>

              <div class="mt-4">
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="
                    !getForm('dietPlannerProfile', getForm('corporate')).get(
                      'allergies'
                    )?.valid
                  "
                  (click)="next()"
                >
                  Next <mat-icon>check</mat-icon>
                </button>
                &nbsp;
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="prev()"
                >
                  Back
                </button>
              </div>
            </div>

            <div *ngIf="state === 19" class="w-80" @fade>
              <h1>
                Amazing!!! You did a good job. Thanks for providing the details.
              </h1>
              <button
                type="button"
                mat-flat-button
                color="primary"
                (click)="onSubmit()"
              >
                Submit
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
    <div *ngIf="dietPlanUrl" class="text-center">
      <iframe
        [src]="dietPlanUrl | safe"
        style="width: 700px; height: 800px"
      ></iframe>
    </div>
  </div>
</section>
