<div class="container mt-4">
  <div class="row">
    <div class="col-md-12">
      <h1 class="title">Over <b>20+</b> Clients all over the globe.</h1>
    </div>
  </div>
</div>
<div class="container-fluid p-0">
  <!-- <img class="img-fluid" src="assets/img/clients.png" alt="clients" /> -->

  <ngx-slick-carousel
    class="carousel tdr-team"
    #slickModal="slick-carousel"
    [config]="slideConfig"
  >
    <div *ngFor="let slideArr of slides">
      <div class="flex-box">
        <div
          ngxSlickItem
          *ngFor="let slide of slideArr"
          class="slide tdr-team-box"
          [ngClass]="slide.class"
        >
          <div class="tdr-team-inner-box" *ngFor="let item of slide.items">
            <img src="{{ item.img }}" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
