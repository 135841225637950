import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wellness',
  templateUrl: './wellness.component.html',
  styleUrls: ['./wellness.component.scss'],
})
export class WellnessComponent {
  saasBaseUrl = environment.saasBaseUrl;
  modules: {
    title: string;
    description: string;
    img: string;
    link?: string;
  }[] = [
    {
      title: 'Mindfulness meditation',
      description: 'Guided sessions in wonder worlds',
      img: 'assets/img/wellness/mindfulness-psytechv.svg',
    },
    {
      title: 'Art therapy',
      description: 'Neurographic art',
      img: 'assets/img/wellness/art-therapy-psytechv.svg',
    },
    {
      title: 'Travel therapy',
      description: 'Unique 360 videos, 4K resolution',
      img: 'assets/img/wellness/travel-therapy-psyte.svg',
    },
  ];
}
