import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiResponse } from '../models/api-res.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalServiceBookingService {
  constructor(private _httpClient: HttpClient) {}

  Create(data: any): Observable<IApiResponse<number>> {
    return this._httpClient.post<IApiResponse<number>>(
      `${environment.apiUrl}/g-core/v1.0/external-service-booking`,
      data
    );
  }

  GetDietPlan(
    bookingId: number,
    params?: HttpParams
  ): Observable<IApiResponse<string>> {
    return this._httpClient.get<IApiResponse<string>>(
      `${environment.apiUrl}/g-core/v1.0/external-service-booking/diet-plan/${bookingId}`,
      { params: params }
    );
  }
}
