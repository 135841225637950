<section class="section gradient">
  <div class="container">
    <h1 class="text-center">Benefits</h1>

    <div class="row">
      <div class="col-lg-6 my-5" *ngFor="let module of modules; let i = index">
        <div class="nbr-box h-100 d-flex align-items-center">
          <span>{{ i + 1 }}</span>
          <div>
            <h4>{{ module.title }}</h4>
            <p>
              {{ module.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
