import { Component } from '@angular/core';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss'],
})
export class OurClientsComponent {
  slides: { items: { img: string }[]; class?: string }[][] = [
    [
      { items: [{ img: 'assets/img/clients/c-1.png' }], class: 'large-shadow' },
      {
        items: [
          { img: 'assets/img/clients/c-2.png' },
          { img: 'assets/img/clients/c-3.png' },
        ],
      },
      {
        items: [
          { img: 'assets/img/clients/c-4.png' },
          { img: 'assets/img/clients/c-5.png' },
        ],
      },
      {
        items: [{ img: 'assets/img/clients/c-6.png' }],
        class: 'small-shadow',
      },
      {
        items: [
          { img: 'assets/img/clients/c-7.png' },
          { img: 'assets/img/clients/c-8.png' },
        ],
      },
    ],
    [
      { items: [{ img: 'assets/img/clients/c-9.png' }], class: 'large-shadow' },
      {
        items: [
          { img: 'assets/img/clients/c-10.png' },
          { img: 'assets/img/clients/c-11.png' },
        ],
      },
      {
        items: [
          { img: 'assets/img/clients/c-12.png' },
          { img: 'assets/img/clients/c-4.png' },
        ],
      },
      {
        items: [{ img: 'assets/img/clients/c-5.png' }],
        class: 'small-shadow',
      },
      {
        items: [
          { img: 'assets/img/clients/c-6.png' },
          { img: 'assets/img/clients/c-7.png' },
        ],
      },
    ],
  ];

  slideConfig = {
    arrows: false,
    dots: false,
    fade: false,
    infinite: true,
    autoplay: true,
    // centerMode: true,
    speed: 300,
  };
}
