import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-connect-with',
  templateUrl: './connect-with.component.html',
  styleUrls: ['./connect-with.component.scss'],
})
export class ConnectWithComponent {
  @Input() connectWith = 'us';
}
